import { AGENT_MODES } from './Model/Enums/AgentModes';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgentModeService {

  public onAgentModeChanged$ = new Subject<AGENT_MODES>();

  private currentAgentMode = AGENT_MODES.AGENT_MODE_DEFAULT;

  constructor() { }

  public SetCurrentAgentMode(mode: AGENT_MODES): void {
    this.currentAgentMode = mode;
    this.onAgentModeChanged$.next(this.currentAgentMode);
  }

  public GetCurrentAgentMode(): AGENT_MODES {
    return this.currentAgentMode;
  }

  public ResetAll(): void {
    this.SetCurrentAgentMode(AGENT_MODES.AGENT_MODE_DEFAULT);
  }
}
