import { Component } from '@angular/core';
import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';

@Component({
  selector: 'app-cad-display',
  templateUrl: './cad-display.component.html',
  styleUrls: ['./cad-display.component.css']
})
export class CadDisplayComponent {
  collapseToggle: boolean;
  constructor(private loggerService: LoggerService, protected storageService: StorageService) {
    this.loggerService.logger.logDebug('main: Constructor start');
    this.collapseToggle = true;
    this.loggerService.logger.logDebug('main: Constructor complete');
  }

  protected expandAndCollapse(size: string) {
    if (size === 'Expand') {
      this.collapseToggle = true;
    } else {
      this.collapseToggle = false;
    }
  }

  protected getCadDisplayKeys(cadInfo: object) {
    return Object.keys(cadInfo);
  }
}
