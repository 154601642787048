import {
  CHANNEL_TYPES,
  CONTEXTUAL_OPERATION_TYPE,
  IInteraction,
  LOG_LEVEL,
  clickToAct,
  getConfig
} from '@amc-technology/davinci-api';
import { Presence, Reason } from './Model/Enums/Presence';

import { IKeyValuePair } from './Model/IKeyValuePair';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class ClickToActService {
  private cname = 'ClickToActService';
  private channelToSiebelMap: Map<string, string> = new Map([]);
  private siebelToChannelMap: Map<string, string> = new Map([]);

  constructor(private loggerService: LoggerService) {
    this.readConfig();
  }


  async readConfig() {
    const fname = 'readConfig';
    try {
      const config = await getConfig();
      if (
        config['PresenceMapping'] &&
        config['PresenceMapping']['variables'] &&
        config['PresenceMapping']['variables']['channelToSiebel']
      ) {
        this.channelToSiebelMap = config['PresenceMapping']['variables']['channelToSiebel'];
      }

      if (
        config['PresenceMapping'] &&
        config['PresenceMapping']['variables'] &&
        config['PresenceMapping']['variables']['siebelToChannel']
      ) {
        this.siebelToChannelMap = config['PresenceMapping']['variables']['siebelToChannel'];
      };
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to read config. ${JSON.stringify(error)}`);
    }
  }

  setPresence(crmPresence: string, crmReason: string = '') {
    const fname = 'setPresence';
    try {
      this.loggerService.logger.logInformation(`${fname} : Start: Setting presence to ${crmPresence}`);
      let presenceMapped = '';
      if (crmReason) {
        presenceMapped = this.siebelToChannelMap[`${crmPresence}|${crmReason}`];
      } else {
        presenceMapped = this.siebelToChannelMap[crmPresence];
      }
      let presence = '';
      let reason = '';
      let pending = '';
      if (presenceMapped.includes('|')) {
        // This will split a presence if it maps to a reason code using the | character
        const presenceReason = presenceMapped.split('|');
        presence = presenceReason[0];
        reason = presenceReason[1];
        this.loggerService.logger.logTrace(`${fname} : Reason ${reason}`);
        if (presenceReason.length > 2) {
          pending = presenceReason[2];
          this.loggerService.logger.logTrace(`${fname} : Presence: ${presence} | Reason: ${reason} | Pending: ${pending}`);
        }
      } else {
        this.loggerService.logger.logTrace('No reason code, just presence');
        presence = presenceMapped;
      }

      if (presence === 'Ready' && reason === '') {
        this.loggerService.logger.logInformation('Presence is Ready. No Reason');
        reason = 'Ready'; // The default reason for Ready is Ready
      } else if (presence === 'NotReady' && reason === '') {
        this.loggerService.logger.logInformation('Presence is Not Ready. No Reason');
        reason = 'Break'; // The default reason for Not Ready is Break
      }
      this.loggerService.logger.logInformation(`${fname} : Sending presence to CTI : Presence: ${presence} | Reason: ${reason} | Pending: ${pending}`);

      clickToAct(
        '',
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.SetPresence,
        '',
        {presence: presence, reason: reason, pending: pending}
      );
      this.loggerService.logger.logInformation(`${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${fname} : Failed to send presence. ${JSON.stringify(error)}`);
    }
  }

  answerCall(interaction: IInteraction) {
    const fname = 'answerCall';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start : Interaction ID: ${interaction.interactionId}`);
      clickToAct(
        interaction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Answer,
        interaction.interactionId,
        interaction
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to answer call. ${JSON.stringify(error)}`);
    }
  }

  hangupCall(interaction: IInteraction) {
    const fname = 'hangupCall';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start : Interaction ID: ${interaction.interactionId}`);
      clickToAct(
        interaction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Hangup,
        interaction.interactionId,
        interaction
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to hangup call. ${JSON.stringify(error)}`);
    }
  }

  holdCall(interaction: IInteraction) {
    const fname = 'holdCall';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start : Interaction ID: ${interaction.interactionId}`);
      clickToAct(
        interaction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Hold,
        interaction.interactionId,
        interaction
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to hold call. ${JSON.stringify(error)}`);
    }
  }

  unholdCall(interaction: IInteraction) {
    const fname = 'unholdCall';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start : Interaction ID: ${interaction.interactionId} : Phone Number: ${interaction.details.fields['Phone']['Value']}`);
      clickToAct(
        interaction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Unhold,
        interaction.interactionId,
        interaction
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to unhold call. ${JSON.stringify(error)}`);
    }
  }

  swapCalls(unholdInteraction: IInteraction, holdInteraction: IInteraction) {
    const fname = 'swapCalls';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, fname, `Swapping calls. Unhold: ${unholdInteraction.interactionId} | Hold: ${holdInteraction.interactionId}`, { unhold: unholdInteraction, hold: holdInteraction });
      clickToAct(
        unholdInteraction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Swap,
        unholdInteraction.interactionId,
        {unholdInteraction: unholdInteraction, holdInteraction: holdInteraction}
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to swap calls. ${JSON.stringify(error)}`);
    }
  }

  /**
   * Sends a warm transfer request to the DaVinci Framework. Handles both starting and completing
   * a warm transfer.
   *
   * @param {IInteraction} interaction Interaction that is to be transferred
   * @param {string} phoneNumber Extension or number that will receive the transfer
   * @param {boolean} isWarmTransferInit True if starting a warm transfer, false if completing a warm transfer
   * @memberof ClickToActService
   */
  warmTransferCall(interaction: IInteraction, phoneNumber: string, isWarmTransferInit: boolean) {
    const fname = 'warmTransferCall';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start: Interaction ID: ${interaction.interactionId} : Phone Number: ${phoneNumber}`);
      clickToAct(
        phoneNumber,
        null,
        CHANNEL_TYPES.Telephony,
        isWarmTransferInit ? CONTEXTUAL_OPERATION_TYPE.AddParticipant : CONTEXTUAL_OPERATION_TYPE.WarmTransfer,
        interaction.interactionId,
        interaction
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to warm transfer call. ${JSON.stringify(error)}`);
    }
  }

  conferenceCall(interaction: IInteraction, phoneNumber: string, isConferenceInit) {
    const fname = `${this.cname}.conferenceCall()`;
    this.loggerService.logger.logInformation(`${fname} : Start: Interaction ID: ${interaction.interactionId} : Phone Number: ${phoneNumber}`);
    try {
      clickToAct(
        phoneNumber,
        null,
        CHANNEL_TYPES.Telephony,
        isConferenceInit ? CONTEXTUAL_OPERATION_TYPE.AddParticipant : CONTEXTUAL_OPERATION_TYPE.Conference,
        interaction.interactionId,
        interaction
      );
    } catch (err) {
      this.loggerService.logger.logError(`${fname}: Failed to initiate conference. ${JSON.stringify(err)}`);
    }
    this.loggerService.logger.logInformation(`${fname} : END`);
  }

  blindTransferCall(interaction: IInteraction, phoneNumber: string) {
    const fname = 'blindTransferCall';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start`);
      clickToAct(
        phoneNumber,
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.BlindTransfer,
        interaction.interactionId,
        interaction
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to blind transfer call. ${JSON.stringify(error)}`);
    }
  }

  setDisposition(interaction: IInteraction, disposition: string) {
    const fname = 'setDisposition';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start`);
      this.loggerService.log(LOG_LEVEL.Debug, fname, `Setting disposition`, { interactionId: interaction.interactionId, scenarioId: interaction.scenarioId, disposition: disposition });
      clickToAct(
        interaction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.SetDisposition,
        interaction.interactionId,
        {
          disposition: disposition,
          interaction: interaction
        }
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, `Failed to set disposition`, { error: error });
    }
  }

  sendDTMF(interaction: IInteraction, dtmf: string) {
    const fname = 'sendDTMF';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start`);
      clickToAct(
        interaction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.DTMF,
        interaction.interactionId,
        {dtmf: dtmf}
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to send DTMF. ${JSON.stringify(error)}`);
    }
  }

  muteCall(interaction: IInteraction) {
    const fname = 'muteCall';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start`);
      clickToAct(
        interaction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Mute,
        interaction.interactionId,
        interaction
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to mute call. ${JSON.stringify(error)}`);
    }
  }

  unmuteCall(interaction: IInteraction) {
    const fname = 'unmuteCall';
    try {
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : Start`);
      clickToAct(
        interaction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.Unmute,
        interaction.interactionId,
        interaction
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to unmute call. ${JSON.stringify(error)}`);
    }
  }

  retrieveCall(dropInteraction: IInteraction, holdInteraction: IInteraction) {
    const fname = 'retrieveCall';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, fname, `Sending Drop for Interaction: ${dropInteraction.interactionId} and unhold for ${holdInteraction.interactionId}`, { drop: dropInteraction, hold: holdInteraction });
      clickToAct(
        dropInteraction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.RetrieveCall,
        dropInteraction.interactionId,
        {
          dropInteraction: dropInteraction,
          holdInteraction: holdInteraction
        }
      );
      this.loggerService.logger.logInformation(`${this.cname} : ${fname} : END`);
    } catch (error) {
      this.loggerService.logger.logError(`${this.cname} : ${fname} : Failed to retrieve call. ${JSON.stringify(error)}`);
    }
  }

  updateCAD(interaction: IInteraction, cadItems: IKeyValuePair) {
    const fname = 'updateCAD';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, fname, `CAD Items: ${JSON.stringify(cadItems)}`);
      clickToAct(
        interaction.details.fields['Phone']['Value'],
        null,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.UpdateCAD,
        interaction.interactionId,
        cadItems
      );

    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, `Failed to send CAD.`, { error: error });
    }
  }
}
