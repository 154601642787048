import { LEGACY_WORKMODE } from "./Model/Enums/LegacyWorkmode";
import { EVENT_CODES } from "./Model/Enums/EventCodes";
import { CallHistoryManagerService } from "./call-history-manager.service";
import { AGENT_MODES } from "./Model/Enums/AgentModes";
import { AMC_CONNECTION_STATES } from "./Model/Enums/AmcConnectionStates";
import { CallData } from "./Model/CallData";
import { CallStateEvent } from "./Model/CallStateEvent";
import { Call, CallProperties } from "./Model/Call";
import { CommandParameter } from "./Model/Enums/CommandParameter";
import { SCErrorCode } from "./Model/Enums/SCErrorCode";
import { ClientCommands } from "./Model/Enums/ClientCommands";
import {
  CommandState,
  SiebelCommandStatusService,
} from "./siebel-command-status.service";
import { ISiebelClient } from "./Model/ISiebelClient";
import { ISiebelCommand } from "./Model/ISiebelCommand";
import { ISiebelDriver } from "./Model/ISiebelDriver";
import { Injectable } from "@angular/core";
import { SCCommandTypeEx } from "./Model/Enums/SCCommandTypeEx";
import { Subject } from "rxjs";
import { BridgeEventsService } from "@amc-technology/applicationangularframework";
import { SCCommandFlag } from "./Model/Enums/SCCommandFlag";
import { SCWorkItemMode } from "./Model/Enums/SCWorkItemMode";
import {
  CommandDescriptions,
  SupportedCommands,
  SupportedEvents,
  EVENTS_ENUM,
} from "./Model/Enums/Commands";
import {
  IGetPresenceResult,
  IInteraction,
  INTERACTION_DIRECTION_TYPES,
  INTERACTION_STATES,
  LOG_LEVEL,
  Logger,
  getPresence,
  logout
} from "@amc-technology/davinci-api";
import { LoggerService } from "./logger.service";
import { SiebelConfigService } from "./siebel-config.service";
import { ServiceSettingsKey } from "./Model/Enums/ServiceSettingsKey";
import { DriverSettingsKey } from "./Model/Enums/DriverSettingsKey";
import { DataStoreService } from "./data-store.service";
import { AgentModeService } from "./agent-mode.service";
import { Presence } from "./Model/Enums/Presence";

@Injectable({
  providedIn: "root",
})
export class ScapiService implements ISiebelClient {
  // TODO: Fire observables for all SiebelService and SiebelDriver functions. The driver in Siebel will have to handle them, but the Cloud app could still react to it if necessary
  public readonly $InvokeCommand = new Subject<ISiebelCommand>();
  public static readonly ClientCommand = "ClientCommand";

  private readonly cname = "ScapiService";
  private bridgeEventService: BridgeEventsService;
  private commandsList = Object.values(SupportedCommands);
  private commandDescriptionsList = Object.values(CommandDescriptions);
  private commandStatuses = this.commandsList.map(
    (val) => SCCommandFlag.SC_CF_DISABLED
  );
  private scenarioMap: Map<string, Map<string, IInteraction>> = new Map();
  public interactionToWorkItemMap: Map<string, IInteraction> = new Map();
  private logger: Logger;

  ////////////////////////////////////////////
  // Studio Config Parameters               //
  ////////////////////////////////////////////
  public enableDispositionOnCall: boolean = false;
  public dispositionSentDisconnect: Set<string> = new Set<string>();

  ////////////////////////////////////////////
  //        DEF Config Parameters           //
  ////////////////////////////////////////////
  private user = "";
  private bShowAllCallNotifications = false;
  private bRefreshLargeCallHandleFirst = false;
  private bSendDropEventFirst = false;
  private bSupportOutbound = false;
  private bSkipPreviewDisposition = false;
  private bSkipCallDisposition = false;
  private bDisableWorkmodeDuringCalls = false;
  private bLetDropEventCleanWorkItems = false;
  private getRefreshOrderOnTwoCalls = false;
  private bUpdateWorkmodeOnActiveCall = false;
  private wstrPreviewMessage = "";
  private wstrTimedPreviewKeyName = "";
  private wstrPreviewANIFromCADField = "";
  private bDisplayDNISOnAlert = false;
  private bSendConnectedAfterAnswer = false;
  private wstrPredictiveMessage = "Predictive";
  private bGetRefreshOrderOnTwoCalls = false;
  private bRemoveCommaBeforeANI = false;
  private bAutoDisposition = false;

  public wstrAgentDefaultStateOnLogin = "2";
  private bSupportLogoutReason = false;
  private bAutoLogout = true;
  public bIgnoreLogin = false;
  private wstrLogoutReasonKey = "LOGOUTREASON";
  private bUseWorkmodeButtonForACW = false;
  private bBlockEventsDuringLogout = false;

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  private bCanAnswer = true;
  private bCanConference = true;
  private bInPreviewDisposition = false;
  private bInPreviewDirectDisposition = false;
  private bDelayWMForErrorMessage = false;
  private bIsConsultCallRetrieved = false;
  public bHasLoginCTI = true;
  private bNotSetNotReadyAfterDropCall = false;
  private bInPreviewState = false;
  private bInReservedPredictiveState = false;
  private bHasPendingLogout = false;
  private bHasTwoContacts = false;
  private bHasOneHeldContact = false;
  private bHasTwoParties = false;
  private bInPreviewAndDispositionPeriod = false;
  private m_WorkMode = 0;
  private makecallData: CallData[] = [];
  private makecallDataForConsult: CallData[] = [];
  private callDataParams: string[] = [];

  private CallState2EventMap = {
    [INTERACTION_STATES.Alerting]: SupportedEvents.MCISAlerting,
    [INTERACTION_STATES.Connected]: SupportedEvents.MCISConnected,
    [INTERACTION_STATES.OnHold]: SupportedEvents.MCISHeld,
    [INTERACTION_STATES.Disconnected]: SupportedEvents.MCISDropped,
    [INTERACTION_STATES.Initiated]: SupportedEvents.MCISInitiated,
  };

  private StateHistory2EventMap = {
    [INTERACTION_STATES.Alerting]: {
      [INTERACTION_STATES.Connected]: SupportedEvents.MCISAnswered,
    },
    [INTERACTION_STATES.Connected]: {},
    [INTERACTION_STATES.OnHold]: {
      [INTERACTION_STATES.Connected]: SupportedEvents.MCISRetrieved,
    },
    [INTERACTION_STATES.Disconnected]: {},
    [INTERACTION_STATES.Initiated]: {},
  };

  constructor(
    private loggerService: LoggerService,
    private commandStatus: SiebelCommandStatusService,
    private siebelConfig: SiebelConfigService,
    private callHistoryManager: CallHistoryManagerService,
    private dataStore: DataStoreService,
    private agentModeService: AgentModeService
  ) {
    this.logger = this.loggerService.logger;
    this.siebelConfig.onConfigSet.subscribe(this.readSiebelConfig.bind(this));
  }

  ///////////////////////////////////////////////////////////////////////////////////
  //                          Siebel Client Functions                              //
  ///////////////////////////////////////////////////////////////////////////////////

  async BeginBatch(): Promise<void> {
    let command = ClientCommands.BeginBatch;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
    });
  }

  async CacheCommandInformation(
    commandNames: string[],
    commandDescriptions: string[],
    commandStatuses: SCCommandFlag[]
  ): Promise<void> {
    let command = ClientCommands.CacheCommandInformation;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      commandNames,
      commandDescriptions,
      commandStatuses,
    });
  }
  async CleanAllWorkItem(): Promise<void> {
    let command = ClientCommands.CleanAllWorkItem;
    INTERACTION_STATES.Alerting;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
    });
  }

  async EndBatch(): Promise<void> {
    let command = ClientCommands.EndBatch;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
    });
  }

  async HandleError(clntCmdTrackID: string, error: string): Promise<void> {
    let command = ClientCommands.HandleError;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      clntCmdTrackID,
      error,
    });
  }

  async HandleEvent(
    name: string,
    fields: any,
    notifyWhenDone: boolean,
    trackingId?: string
  ): Promise<void> {
    let command = ClientCommands.HandleEvent;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      name,
      fields,
      notifyWhenDone,
      trackingId,
    });
  }

  async IndicateNewWorkItem(
    trackingId: string,
    oldTrackingId: string,
    description: string,
    workItemMode: SCWorkItemMode
  ): Promise<void> {
    let command = ClientCommands.IndicateNewWorkItem;
    await this.BeginBatch();
    await this.SendClientMessage("IndicateNewWork: " + trackingId + " (" + oldTrackingId + ")");
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      trackingId,
      oldTrackingId,
      description,
      workItemMode,
    });

    await this.EndBatch();
  }

  async ShowStatusText(text: string): Promise<void> {
    let command = ClientCommands.ShowStatusText;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      text,
    });
  }

  async UpdateObjectInformation(
    trackingId: string,
    mediaTargetAddr: string,
    datasetInfo: any
  ): Promise<void> {
    let command = ClientCommands.UpdateObjectInformation;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      trackingId,
      mediaTargetAddr,
      datasetInfo,
    });
  }

  async WorkItemReleased(
    trackingId: string,
    stopTimeInSeconds: number
  ): Promise<void> {
    let command = ClientCommands.WorkItemReleased;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      trackingId,
      stopTimeInSeconds,
    });
  }

  async WorkItemResumed(trackingId: string): Promise<void> {
    let command = ClientCommands.WorkItemResumed;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      trackingId,
    });
  }

  async WorkItemStarted(
    trackingId: string,
    oldTrackingId: string,
    description: string,
    mediaTargetAddr: string,
    startTimeInSeconds: number
  ): Promise<void> {
    let command = ClientCommands.WorkItemStarted;
    await this.BeginBatch();
    await this.SendClientMessage("WorkItemStarted: " + trackingId + " (" + oldTrackingId + ")");
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      trackingId,
      oldTrackingId,
      description,
      mediaTargetAddr,
      startTimeInSeconds,
    });
    await this.EndBatch();
  }

  async WorkItemSuspended(trackingId: string): Promise<void> {
    let command = ClientCommands.WorkItemSuspended;
    await this.bridgeEventService.sendEvent(ScapiService.ClientCommand, {
      command,
      trackingId,
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////
  //                           Cloud App Functions                                 //
  ///////////////////////////////////////////////////////////////////////////////////

  public initialize(bridgeEventService: BridgeEventsService) {
    this.siebelConfig.onConfigSet.subscribe(this.readSiebelConfig.bind(this));
    if (!this.bridgeEventService) {
      this.bridgeEventService = bridgeEventService;
      this.dataStore.initialize({bridgeEventService, loggerService: this.loggerService});
      try {
        this.bridgeEventService.subscribe("InvokeCommand", (event) => {
          this.InvokeCommand(
            event.clntCmdTrackID,
            event.name,
            event.stringParam,
            typeof event.datasetParam == "string"
              ? JSON.parse(event.datasetParam)
              : event.datasetParam
          );
        });
      } catch (e) {
        this.loggerService.logger.logError(JSON.stringify(e));
      }
    }
  }

  public updateInteractionToWorkItemMap(interaction: IInteraction) {
    const fname = 'updateInteractionMap';
    try {
      const workItemId = interaction.interactionId.replace(/[\sA-Za-z]*/g, "");
      this.loggerService.log(LOG_LEVEL.Trace, fname, 'Updating Interaction Map', { interactionId: interaction.interactionId, workItemId, state: interaction.state, interaction });
      // if (this.interactionToWorkItemMap.has(workItemId)) {
        this.interactionToWorkItemMap.set(workItemId, interaction);
      // }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Unable to update Interaction Map', { interactionId: interaction.interactionId, error });
    }
  }

  public removeInteractionFromWorkItemMap(interaction: IInteraction) {
    const fname = 'removeInteractionMap';
    try {
      const workItemId = interaction.interactionId.replace(/[\sA-Za-z]*/g, "");
      this.loggerService.log(LOG_LEVEL.Trace, fname, 'Removing Interaction Map', { interactionId: interaction.interactionId, workItemId, state: interaction.state, interaction });
      if (this.interactionToWorkItemMap.has(workItemId)) {
        this.loggerService.log(LOG_LEVEL.Trace, fname, 'Removing Interaction Map', { interactionId: interaction.interactionId, workItemId, state: interaction.state, interaction });
        this.interactionToWorkItemMap.delete(workItemId);
      } else {
        this.loggerService.log(LOG_LEVEL.Warning, fname, 'Interaction not found in Interaction Map', { interactionId: interaction.interactionId, workItemId, state: interaction.state, interaction });
      }
      if (this.dispositionSentDisconnect.has(interaction.interactionId)) {
        this.loggerService.log(LOG_LEVEL.Trace, fname, 'Removing Interaction from disposition set.' , { interactionId: interaction.interactionId, dispositionSentDisconnect: this.dispositionSentDisconnect });
        this.dispositionSentDisconnect.delete(interaction.interactionId);
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Unable to remove Interaction Map', { interactionId: interaction.interactionId, error });
    }
  }

  public getActiveInteractionsFromWorkItemMap(): IInteraction[] {
    const fname = 'getActiveInteractionsFromWorkItemMap';
    try {
      const activeInteractions = Array.from(this.interactionToWorkItemMap.values()).filter((interaction) => interaction.state !== INTERACTION_STATES.Disconnected);
      this.loggerService.log(LOG_LEVEL.Trace, fname, 'Active Interactions', { activeInteractions });
      return activeInteractions;
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Unable to get Active Interactions', { error });
      return [];
    }
  }

  public async SetCommands(
    commands: SupportedCommands[],
    statuses: SCCommandFlag[]
  ) {
    if (commands.length != statuses.length) {
      throw new Error("Must have a status for every command that is given");
    }

    for (let i = 0; i < commands.length; i++) {
      this.commandStatuses[this.commandsList.indexOf(commands[i])] =
        statuses[i];
    }

    this.loggerService.log(LOG_LEVEL.Trace, "SetCommands", "Setting Commands", { commands: this.commandsList, statuses: this.commandStatuses });

    await this.CacheCommandInformation(
      this.commandsList,
      this.commandsList,
      this.commandStatuses
    );
  }

  public async SetCommandStates(commandStates: CommandState[]) {
    let commands = commandStates.map((cs) => cs.command);
    let statuses = commandStates.map((cs) => cs.status);

    await this.SetCommands(commands, statuses);
  }

  public async RaiseNewContactEvent(interaction: IInteraction) {
    this.processInteraction(interaction);
    const channelAddr = this.getPhone(interaction);

    this.loggerService.logger.logInformation(
      "Raising New Contact Event from Service: " +
        this.CallState2EventMap[interaction.state]
    );

    await this.BeginBatch();

    // Raise an alert to agent that a new work item has been received

    await this.IndicateNewWorkItem(
      interaction.scenarioId,
      interaction.scenarioId,
      channelAddr,
      interaction.direction == INTERACTION_DIRECTION_TYPES.Inbound
        ? SCWorkItemMode.SC_WM_INBOUND
        : SCWorkItemMode.SC_WM_OUTBOUND
    );

    // Start the work item

    await this.WorkItemStarted(
      interaction.interactionId,
      interaction.interactionId,
      channelAddr,
      "Agent",
      this.GetDateInSeconds()
    );

    // Raise the proper event.

    await this.HandleEvent(
      this.CallState2EventMap[interaction.state],
      { ANI: channelAddr },
      false
    );

    await this.ShowStatusText("New contact received");

    // TODO: We are ending the work item on Disconnected. Rework once design plan for disposition is complete
    // if (this.getDriverEvent(interaction) == SupportedEvents.MCISDisposition) {

    if (
      this.CallState2EventMap[interaction.state] == SupportedEvents.MCISDropped
    ) {
      await this.WorkItemReleased(
        interaction.scenarioId,
        this.GetDateInSeconds()
      );
    }

    await this.EndBatch();

    this.updateInteractionMap(interaction);
  }

  public async RaiseContactEvent(interaction) {
    this.processInteraction(interaction);

    const channelAddr = this.getPhone(interaction);

    this.loggerService.logger.logInformation(
      "Raising Contact Event from SCAPI-Service: " +
        this.CallState2EventMap[interaction.state]
    );

    let driverEvent = this.getDriverEvent(interaction);

    await this.BeginBatch();

    await this.ShowStatusText(
      `Contact State Changed: ${interaction.state}, Raising event: ${driverEvent}`
    );

    await this.HandleEvent(driverEvent, { ANI: channelAddr }, false);

    // TODO: We are ending the work item on Disconnected. Rework once design plan for disposition is complete
    // if (this.getDriverEvent(interaction) == SupportedEvents.MCISDisposition) {

    if (driverEvent == SupportedEvents.MCISDropped) {
      await this.WorkItemReleased(
        interaction.scenarioId,
        this.GetDateInSeconds()
      );
    }

    await this.EndBatch();

    this.updateInteractionMap(interaction);
  }

  public GetCommandStatus(command: SupportedCommands): SCCommandFlag {
    return this.commandStatuses[this.commandsList.indexOf(command)];
  }

  public async OnWorkmodeChangedEvent(mode: number, reason: number = 0) {
    // Note: This function is not currently used and it only exists as a reference for when preview disposition needs to be implemented
    const functionName = "OnWorkmodeChangedEvent";
    try {
      // This logic is mimicking the logic in the HandleMCISEvent function in the premise adapter.
      if (this.bBlockEventsDuringLogout && !this.bHasLoginCTI) {
        // When the blockEventsDuringLogout flag is set, we do not want to process any events while the agent is logging out.
        return;
      }

      this.loggerService.log(LOG_LEVEL.Debug, functionName, `Workmode changed to ${mode} with reason ${reason}`)

      if (mode === 1 && this.bHasPendingLogout) {
        this.bHasPendingLogout = false;
        this.HandleLogin(false);
      }

      this.m_WorkMode = mode;
      this.SetCommandStates(this.commandStatus.WorkmodeChanged(mode, reason));

      let message: string;
      if (mode == 1 ) { // Ready
        message = 'Workmode: Ready';
        this.bInPreviewAndDispositionPeriod = false;
        this.loggerService.log(LOG_LEVEL.Debug, functionName, `Clear cached handle`);
        // this.bstrCachedDropCallHandle = ""; // This is leftover from the premise code and is no longer used.
        this.bInPreviewDisposition = false;
        this.bInPreviewDirectDisposition = false;
      } else if (mode == 2) { // Not Ready
        message = 'Workmode:  Not Ready';
      } else if (mode == 4) {// ACW
        message = 'Workmode: ACW';
        if (this.bUseWorkmodeButtonForACW) {
          this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Setting Not Ready Enabled while in ACW state');
          this.SetCommandStates(this.commandStatus.SetNotReadyEnabled(true));
        } else {
          this.loggerService.log(LOG_LEVEL.Warning, functionName, 'Leaving Not Ready Disabled while in ACW state');
        }
      } else { // Workmode Changed
        message = 'Workmode changed';
      }

      if (this.bDelayWMForErrorMessage && mode === 4) {
        this.bDelayWMForErrorMessage = true;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }

      if (!this.bShowAllCallNotifications) {
        this.SendClientMessage(message);
      } else if (!(mode === 2 && this.bNotSetNotReadyAfterDropCall)) {
        this.SendClientMessage(message);
      }
      this.bNotSetNotReadyAfterDropCall = false;
      // this.UpdateCachedCommandInfo(); // This is leftover from the premise code and is no longer used.
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, functionName, 'An exception ocurred updating agent', error);
    }
  }

  private interactionToScenario(
    interactionId: string,
    scenarioInteractionMappings: {
      [scenarioId: string]: {
          [interactionId: string]: boolean;
      };
    }
  ): string {
    for (const scenarioId of Object.keys(scenarioInteractionMappings)) {
      if (Object.keys(scenarioInteractionMappings[scenarioId]).includes(interactionId)) {
        return scenarioId;
      }
    }
    return interactionId;
  }

  private async ConvertCallStateEvent(
    callStateEvent: CallStateEvent,
    scenarioInteractionMappings: {
      [scenarioId: string]: {
          [interactionId: string]: boolean;
      };
    }
    ) {
    let fname = `${this.cname}.ConvertCallStateEvent()`;

    try {
      for (let call of Object.keys(callStateEvent.Calls)) {
        if (!(callStateEvent.Calls[call].CallProperties)) {
          throw new Error(`No call properties found for call=${call}. callStateEvent=${JSON.stringify(callStateEvent)}`);
        }

        let handle = this.interactionToScenario(callStateEvent.Calls[call].Handle, scenarioInteractionMappings);

        let storedProperties = JSON.parse((await this.dataStore.retrieveData(handle)) || "{}");
        callStateEvent.Calls[call].CallProperties = {
          ...storedProperties,
          ...(callStateEvent.Calls[call].CallProperties)
        };
      }
    } catch (err) {
      this.logger.logError(`${fname}: ERROR: ${JSON.stringify(err)}`);
      throw err;
    }
  }

  public async OnContactEvent(
    eventId: EVENT_CODES,
    currCallState: CallStateEvent,
    scenarioInteractionMappings: {
      [scenarioId: string]: {
          [interactionId: string]: boolean;
      };
    }
  ) {

    // This logic is mimicking the logic in the HandleMCISEvent function in the premise adapter.
    if (
      eventId === EVENT_CODES.EVENT_CONTACT_CHANGED &&
      this.bBlockEventsDuringLogout
    ) {
      // When the blockEventsDuringLogout flag is set, we do not want to process any events while the agent is logging out.
      return;
    }

    const fname = "OnContactEvent()";
    try {
      this.logger.logDebug(`${this.cname}.${fname}: START`);
      this.loggerService.log(LOG_LEVEL.Trace, fname, `EventId: ${eventId}`, { eventId, currCallState, scenarioInteractionMappings });
      await this.ConvertCallStateEvent(currCallState, scenarioInteractionMappings);

      this.logger.logTrace(
        `${this.cname}.${fname}: Disposition status [User = ${this.user}] [bInPreviewDisposition is ${this.bInPreviewDisposition}]`
      );
      this.logger.logTrace(
        `${this.cname}.${fname}: Disposition status [User = ${this.user}] [bInPreviewDirectDisposition is ${this.bInPreviewDirectDisposition}]`
      );

      let bIsPreviousInInitiatedState = false;
      if (this.bShowAllCallNotifications) {
        const previousState = this.callHistoryManager.GetLastKnownState(
          currCallState.EventHandle
        );
        this.logger.logTrace(
          `${this.cname}.${fname}: Handle: ${currCallState.EventHandle} | previous state = ${previousState}`
        );

        bIsPreviousInInitiatedState =
          previousState == AMC_CONNECTION_STATES.AMC_CSTATE_INITIATED ||
          previousState == AMC_CONNECTION_STATES.AMC_CSTATE_FAILED ||
          previousState == AMC_CONNECTION_STATES.AMC_CSTATE_NULL;
      }

      if (Object.keys(currCallState.Calls).length == 0) {
        // line 5658
        this.logger.logTrace(`${this.cname}.${fname}: No Contacts`);
        this.callHistoryManager.ClearAllCalls();

        this.bIsConsultCallRetrieved = false;

        if (eventId == EVENT_CODES.EVENT_CONTACT_DROPPED) {
          // 5682
          if (this.bSendDropEventFirst) {
            await this.RaiseWorkItemReleased(currCallState.EventHandle);
          }

          if (this.bSupportOutbound) {
            if (this.bInPreviewDisposition && !this.bSkipPreviewDisposition) {
              this.logger.logTrace(
                `${this.cname}.${fname}: InPreviewDisposition is true`
              );
              // await this.SetCommandStates(
              //   this.commandStatus.SetPreviewDispositionChecked(true)
              // );
            } else if (
              this.bInPreviewDirectDisposition &&
              !this.bSkipPreviewDisposition
            ) {
              this.logger.logTrace(
                `${this.cname}.${fname}: InPreviewDirectDisposition is true`
              );
              // await this.SetCommandStates(
              //   this.commandStatus.SetPreviewDirectDispositionChecked(true)
              // );
            } else {
              if (!this.bSkipCallDisposition) {
                this.logger.logTrace(
                  `${this.cname}.${fname}: Enable Disposition button [User = ${this.user}] [Handle = ${eventId}]`
                );
                // await this.SetCommandStates(
                //   this.commandStatus.SetDispositionChecked(true)
                // );
              }
            }

            if (this.bInPreviewState && !this.bAutoDisposition) {
              // 5719
              this.bInPreviewAndDispositionPeriod = true;
            }
          }

          await this.RaiseAMCSiebelContactEvent(
            EVENTS_ENUM.EVT_MCIS_DROPPED,
            currCallState.EventHandle,
            AMC_CONNECTION_STATES.AMC_CSTATE_DROPPED,
            AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
            "",
            {},
            false,
            currCallState.ChannelType
          );
          this.logger.logTrace(
            `${this.cname}.${fname}: MCISDropped | # of calls = 0 [User = ${this.user}]`
          );
          if (this.bShowAllCallNotifications && !bIsPreviousInInitiatedState) {
            if (this.bDisableWorkmodeDuringCalls) {
              // await this.SetCommandStates(
              //   this.commandStatus.SetWorkmodeEnabled(true)
              // );
              // await this.RefreshWorkmode();
            }

            if (!this.bHasLoginCTI) {
              // await this.SetCommandStates(
              //   this.commandStatus.SetWorkmodeEnabled(false)
              // );
            }

            if (
              (this.m_WorkMode == LEGACY_WORKMODE.WM_NOT_READY ||
                this.m_WorkMode == 3) &&
              !this.bInPreviewState
            ) {
              // workmode is Not Ready or "3" (currently unknown what 3 represents)
              await this.ShowStatusText("Call Released - In Wrap Up");
            } else {
              await this.ShowStatusText("Call Released");
            }
            this.bNotSetNotReadyAfterDropCall = true;
          } else {
            if (this.bDisableWorkmodeDuringCalls) {
              // await this.SetCommandStates(
              //   this.commandStatus.SetWorkmodeEnabled(true)
              // );
              // await this.RefreshWorkmode();
            }

            if (!this.bHasLoginCTI) {
              // await this.SetCommandStates(
              //   this.commandStatus.SetWorkmodeEnabled(false)
              // );
            }
          }

          this.bInPreviewState = false;
          this.bInReservedPredictiveState = false;
          // await this.SetCommandStates(
          //   this.commandStatus.SetLogoutEnabled(true)
          // );
          if (this.bSendDropEventFirst) {
            await this.RaiseWorkItemReleased(currCallState.EventHandle);
          }

          // if (this.bHasPendingLogout) {
          // TODO: Review, this is just handling for a pending logout. Might not be necessary for client side
          // }
        } else {
          if (!this.bLetDropEventCleanWorkItems) {
            // 5788
            await this.RaiseCleanAllWorkItem();
          }
          if (this.bDisableWorkmodeDuringCalls) {
            // await this.SetCommandStates(
            //   this.commandStatus.SetWorkmodeEnabled(true)
            // );
            // await this.RefreshWorkmode();
          }
          if (!this.bHasLoginCTI) {
            // await this.SetCommandStates(
            //   this.commandStatus.SetWorkmodeEnabled(false)
            // );
          }
        }

        this.agentModeService.ResetAll() // TODO: (What does this mean inside of the premise adapter?)

        if (
          this.bHasPendingLogout &&
          eventId == EVENT_CODES.EVENT_CONTACT_DROPPED
        ) {
          await this.SendClientMessage("Logged Out");
          this.bHasPendingLogout = false;
          // await this.SetCommandStates(this.commandStatus.LoggedOut());
        } else {
          // await this.SetCommandStates(this.commandStatus.NoContacts());
        }
      } else {
        this.logger.logTrace(
          `${this.cname}.${fname}: There are ${
            Object.keys(currCallState.Calls).length
          } Contacts found`
        );

        // await this.SetCommandStates(
        //   this.commandStatus.SetLogoutEnabled(true)
        // );

        this.bHasTwoContacts = false;
        this.bHasOneHeldContact = false;
        this.bHasTwoParties = false;

        if (Object.keys(currCallState.Calls).length == 2) {
          // 5829
          this.bHasTwoContacts = true;

          if (this.getRefreshOrderOnTwoCalls) {
            // TODO: Reread bRefreshLargeHandleFirst from config
          }
        }
        if (Object.keys(currCallState.Calls).length > 1) {
          //! we may need to add logic here to disable the regular hold and resume buttons while in this state.
          //! identify if the call is a consult transfer or consult conference to enable the appropriate buttons
          // await this.SetCommandStates(
          //   this.commandStatus.AllowSuspendResume(true)
          // );
        } else {
          // await this.SetCommandStates(
          //   this.commandStatus.AllowSuspendResume(false)
          // );
        }

        if (currCallState.EventId == EVENT_CODES.EVENT_CONTACT_DROPPED) {
          // 5860
          if (!this.bSendDropEventFirst) {
            await this.RaiseWorkItemReleased(currCallState.EventHandle);
          }

          // await this.SetCommandStates(
          //   this.commandStatus.SetLogoutEnabled(true)
          // );

          this.callHistoryManager.RemoveCurrentCall(currCallState.EventHandle);
          await this.RaiseAMCSiebelContactEvent(
            EVENTS_ENUM.EVT_MCIS_DROPPED,
            currCallState.EventHandle,
            AMC_CONNECTION_STATES.AMC_CSTATE_DROPPED,
            AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
            "",
            {},
            false,
            currCallState.ChannelType
          );
          if (
            !this.bShowAllCallNotifications &&
            !bIsPreviousInInitiatedState &&
            Object.keys(currCallState.Calls).length == 0
          ) {
            if (
              (this.m_WorkMode == LEGACY_WORKMODE.WM_NOT_READY ||
                this.m_WorkMode == 3) &&
              !this.bInPreviewState
            ) {
              // If workmode is Not Ready or "3" (currently unknown what 3 represents)
              await this.SendClientMessage("Call Released - In Wrap Up");
            } else {
              await this.SendClientMessage("Call Released");
            }
            this.bNotSetNotReadyAfterDropCall = true;
          }

          this.bInPreviewState = false;
          this.bInReservedPredictiveState = false;
          if (this.bSendDropEventFirst) {
            // 5887
            await this.RaiseWorkItemReleased(currCallState.EventHandle);
          }

          // Enable "Resume Work Item" button if Agent B drops call before warm transfer or conference is completed
          const curMode: any = ""; // TODO: Get current Agent mode?
          const currentAgentOperationHandle = ""; // TODO: Get current Agent Operation Handle?

          // Iterate through all calls
          const condition1 = false; // TODO: Condition: Agent Mode is ('Warm Transfer Init' AND 'Conference Init-')
          const condition2 = false; // TODO: Condition: Agent Operation Handle is equal to currentAgentOperationHandle?
          for (const callKey of Object.keys(currCallState.Calls)) {
            if (
              currCallState.Calls[callKey].State ===
                AMC_CONNECTION_STATES.AMC_CSTATE_HELD &&
              condition1 &&
              condition2
            ) {
              this.logger.logTrace(
                `${
                  this.cname
                }.${fname}: Enable 'Resume work item' button for incompleted warm transfer or conference: ${"call.AgentOperationHandle>"}`
              );

              // Enable 'Resume Work Item' button
              if (
                this.bInPreviewDisposition ||
                this.bInPreviewDirectDisposition
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.HeldOutboundContact()
                // );
              } else {
                this.logger.logTrace(
                  `${this.cname}.${fname}: Workmode is ${"<Current Workmode>"}`
                ); // TODO: Get Current Workmode
                await this.commandStatus.HeldContact(
                  this.bUpdateWorkmodeOnActiveCall,
                  0
                ); // TODO: Get Current Workmode
              }
              break;
            }
          }

          // Customer drop
          if (Object.keys(currCallState.Calls).length == 1) {
            // Reset Agent Mode;
          }
        }

        const currentAgentMode = this.agentModeService.GetCurrentAgentMode(); // TODO: Get Current Agent Mode
        this.logger.logTrace(
          `${this.cname}.${fname}: Current Agent Mode: ${currentAgentMode}`
        );

        let bRefreshButtonStatus = false;
        if (this.bRefreshLargeCallHandleFirst) {
          // 5933
          this.logger.logTrace(
            `${this.cname}.${fname}: Processing Contacts in reverse order by large handle first.`
          );

          for (let call of Object.keys(currCallState.Calls)) {
            // TODO: Iterate through all interactions
            if (
              (await this.ProcessOneContact(
                currCallState.Calls[call],
                currCallState,
                currentAgentMode,
                ""
              )) == SCErrorCode.SC_EC_OK ||
              eventId == EVENT_CODES.EVENT_CONTACT_DROPPED
            ) {
              // await this.SetCommandStates(
              //   this.commandStatus.SetLogoutEnabled(true)
              // );
              bRefreshButtonStatus = true;
            }
          }

          // After held, we want to refresh alerting call at last
          if (Object.keys(currCallState.Calls).length == 2) {
            // TODO: This exact if statement is used earlier in the function
            // 5945
            this.bRefreshLargeCallHandleFirst = false;
          }
        } else {
          this.logger.logTrace(
            `${this.cname}.${fname}: Processing Contacts in normal order by small handle first.`
          );

          const fakeCallsArray = [];
          for (let call of Object.keys(currCallState.Calls)) {
            // TODO: Iterate through all calls
            if (
              (await this.ProcessOneContact(
                currCallState.Calls[call],
                currCallState,
                currentAgentMode,
                ""
              )) == SCErrorCode.SC_EC_OK ||
              eventId == EVENT_CODES.EVENT_CONTACT_DROPPED
            ) {
              // await this.SetCommandStates(
              //   this.commandStatus.SetLogoutEnabled(true)
              // );
              bRefreshButtonStatus = true;
            }
          }
        }

        if (this.bDisableWorkmodeDuringCalls) {
          // await this.SetCommandStates(
          //   this.commandStatus.SetWorkmodeEnabled(false)
          // );
        }
      }
    } catch (err) {
      this.logger.logError(
        `${this.cname}.${fname}: ERROR: ${JSON.stringify(err)}`
      );
    } finally {
      this.logger.logDebug(`${this.cname}.${fname}: END`);
    }
  }

  public async ProcessOneContact(
    call: Call,
    curCallState: CallStateEvent,
    currentAgentMode: number,
    currentAgentOperationHandle: string
  ): Promise<SCErrorCode> {
    const fname = "ProcessOneContact()";
    let retVal = SCErrorCode.SC_EC_OK;
    try {
      this.logger.logDebug(
        `${this.cname}.${fname}: BEGIN | ${this.user} InteractionID=${call.Handle}`
      );

      // here, if we want, we can put custom description based on config
      let description = "";
      if (
        call.CallProperties[CommandParameter.COMMAND_PARAMETER_CALL_NOTIFY_TEXT]
      ) {
        // TODO: Is this looking for CAD?
        description =
          call.CallProperties[
            CommandParameter.COMMAND_PARAMETER_CALL_NOTIFY_TEXT
          ];

        this.logger.logTrace(
          `${this.cname}.${fname}: CALL_NOTIFY_TEXT = ${description}`
        );
        if (
          description.includes(this.user) ||
          !description.includes("transfer")
        ) {
          description = "";
        }
      }

      if (description.length === 0) {
        if (call.CallParties.length == 1) {
          // TODO: Counting the number of parties on the call?
          // assume only one call
          description = call.CallParties[0].PartyId; // TODO: Description is the ID of the first call party?

          // Fix for empty ANI
          if (description.length == 0) {
            description = "NONE";
          }
        } else if (call.CallParties.length > 1) {
          description = "CONFERENCE";
          this.bHasTwoParties = true;
        } else {
          description = "Default Contact";
        }
      }

      if (this.bInReservedPredictiveState) {
        description += " Predictive Call";
      }

      this.logger.logTrace(
        `${this.cname}.${fname}: Processing Contact: ${description}. Handle: ${call.Handle}. State: ${call.State}. #Parties: ${call.CallParties.length}`
      );

      if (call.State === INTERACTION_STATES.OnHold) {
        this.bHasOneHeldContact = true;
      }

      let wstrPreview = `${description} - ${this.wstrPreviewMessage}`;
      let wstrCallCategory = "";
      let PreviewANI = "";

      let traceLevel = parseInt(
        <string>(
          this.siebelConfig.getDriverSetting(
            DriverSettingsKey.DRIVER_SETTINGS_KEY_TRACE_LEVEL
          )
        )
      );

      if (traceLevel >= 4) {
        for (const key in Object.keys(call.CallProperties)) {
          if (key.includes("CallCategory")) {
            wstrCallCategory = call.CallProperties[key];
          }

          if (
            this.wstrTimedPreviewKeyName.length > 0 &&
            key.includes(this.wstrTimedPreviewKeyName)
          ) {
            if (call.CallProperties[key].length > 0) {
              wstrPreview = `${description} - ${call.CallProperties[key]} Seconds ${this.wstrPreviewMessage}`;
            }
          }

          if (
            this.wstrPreviewANIFromCADField.length > 0 &&
            key.includes(this.wstrPreviewANIFromCADField)
          ) {
            PreviewANI = call.CallProperties[key];
          }
        }
      }

      const condition1 = false; // TODO: curCallState.EventId == EVENT_NEW_WORK? (SiebelService line 6111)
      const condition2 = false; // TODO: call.interactionID = curCallState.interactionID? (SiebelService line 6111)
      if (condition1 && condition2) {
        if (
          this.bSupportOutbound &&
          (call.State == INTERACTION_STATES["Preview"] || this.bInPreviewState)
        ) {
          this.callHistoryManager.AddCurrentCall(
            call.Handle,
            AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW
          );
          if (this.wstrPreviewANIFromCADField && !PreviewANI) {
            // TODO: Get CAD Data with key = this.wstrPreviewANIFromCADField
            PreviewANI = call.CallProperties[this.wstrPreviewANIFromCADField];
            this.logger.logTrace(
              `${this.cname}.${fname}: PreviewANI = ${PreviewANI}`
            );
            if (PreviewANI) {
              description = PreviewANI;
              if (wstrPreview.includes("Seconds")) {
                // TODO: Get CAD Data with key = this.wstrTimerPreviewKeyName
                let wstrTimeout =
                  call.CallProperties[this.wstrTimedPreviewKeyName];
                if (wstrTimeout) {
                  wstrPreview = `${PreviewANI} - ${wstrTimeout} Seconds ${this.wstrPreviewMessage}`;
                }
              }

              await this.RaiseWorkItemStarted(call.Handle, wstrPreview);
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_PREVIEW,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW,
                AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
              // await this.SetCommandStates(this.commandStatus.AcceptPreview());
              this.bInPreviewState = true;
            }
          } else {
            if (wstrPreview.includes("Seconds")) {
              // TODO: Get CAD Data with key = this.wstrTimerPreviewKeyName
              let wstrTimeout =
                call.CallProperties[this.wstrTimedPreviewKeyName];
              if (wstrTimeout) {
                wstrPreview = `${description} - ${wstrTimeout} Seconds ${this.wstrPreviewMessage}`;
              }
            }

            await this.RaiseWorkItemStarted(call.Handle, wstrPreview);
            this.RaiseAMCSiebelContactEvent(
              EVENTS_ENUM.EVT_MCIS_PREVIEW,
              call.Handle,
              AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW,
              AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
              description,
              call.CallProperties,
              this.bHasTwoContacts,
              curCallState.ChannelType
            );
            // await this.SetCommandStates(this.commandStatus.AcceptPreview());
            this.bInPreviewState = true;
          }
        } else {
          this.bInPreviewState = false;
          this.callHistoryManager.AddCurrentCall(
            call.Handle,
            AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING
          );
          let curState: number = this.callHistoryManager.GetLastKnownState(
            call.Handle
          );
          if (call.State == AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING) {
            if (!this.bHasTwoContacts) {
              if (this.bDisplayDNISOnAlert) {
                let wstrDNIS = this.GetDNISFromEventProperty(
                  call.CallProperties
                );
                if (wstrDNIS && wstrDNIS != "0") {
                  let wstrAppendDNIS = `${description} - DNIS: ${wstrDNIS}`;
                  await this.RaiseWorkItemStarted(call.Handle, wstrAppendDNIS);
                }
              } else if (!this.bSendConnectedAfterAnswer) {
                await this.RaiseWorkItemStarted(call.Handle, description);
              }
            }

            if (this.bHasTwoContacts) {
              if (this.bHasOneHeldContact) {
                await this.RaiseNewWorkItem(
                  call.Handle,
                  description,
                  SCWorkItemMode.SC_WM_INBOUND
                );
                await this.RaiseWorkItemStarted(call.Handle, description);
                // await this.SetCommandStates(
                //   this.commandStatus.AlertingContact()
                // );
                this.RaiseAMCSiebelContactEvent(
                  EVENTS_ENUM.EVT_MCIS_ALERTING,
                  call.Handle,
                  AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING,
                  AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                  description,
                  call.CallProperties,
                  this.bHasTwoContacts,
                  curCallState.ChannelType
                );
              }
            } else {
              await this.RaiseNewWorkItem(
                call.Handle,
                description,
                SCWorkItemMode.SC_WM_INBOUND
              );
              await this.RaiseWorkItemStarted(call.Handle, description);
              await this.SetCommandStates(this.commandStatus.AlertingContact());
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_ALERTING,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING,
                AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
            }

            if (!this.bCanAnswer) {
              // await this.SetCommandStates(
              //   this.commandStatus.SetAnswerEnabled(false)
              // );
            }
          } else if (call.State == AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED) {
            this.logger.logTrace(
              `${this.cname}.${fname}: New Work Event with connected state. Assuming Auto-answer is enabled`
            );
            if (
              this.bInPreviewDisposition ||
              this.bInPreviewDirectDisposition
            ) {
              // await this.SetCommandStates(
              //   this.commandStatus.ConnectedOutboundContact()
              // );
            } else {
              this.logger.logTrace(
                `${this.cname}.${fname} Workmode is ${this.m_WorkMode}`
              );
              // await this.SetCommandStates(
              //   this.commandStatus.ConnectedContact(
              //     this.bUpdateWorkmodeOnActiveCall,
              //     this.m_WorkMode
              //   )
              // );
            }

            this.RaiseAMCSiebelContactEvent(
              EVENTS_ENUM.EVT_MCIS_ALERTING,
              call.Handle,
              AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
              AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
              description,
              call.CallProperties,
              this.bHasTwoContacts,
              curCallState.ChannelType
            );

            if (!this.bCanConference) {
              // await this.SetCommandStates(
              //   this.commandStatus.SetConferenceEnabled(false)
              // );
            }
          }
        }
      } else if (call.State == AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING) {
        if (this.bSupportOutbound && this.bInPreviewState) {
          this.logger.logTrace(
            `${this.cname}.${fname}: This is Preview (Outbound).`
          );

          if (this.wstrPreviewANIFromCADField) {
            if (!PreviewANI) {
              // TODO: Get CAD with key = this.wstrPreviewANIFromCADField
              PreviewANI = call.CallProperties[this.wstrPreviewANIFromCADField];
            }
            this.logger.logTrace(
              `${this.cname}.${fname}: PreviewANI = ${PreviewANI}`
            );
            if (PreviewANI) {
              description = PreviewANI;
              wstrPreview = `${description} - ${this.wstrPreviewMessage}`;
              if (wstrPreview.includes("Seconds")) {
                // TODO: Get CAD value with key = this.wstrTimedPreviewKeyName
                let wstrTimeout =
                  call.CallProperties[this.wstrTimedPreviewKeyName];
                if (wstrTimeout) {
                  wstrPreview = `${description} - ${wstrTimeout} Seconds ${this.wstrPreviewMessage}`;
                }
              }

              await this.RaiseWorkItemStarted(call.Handle, wstrPreview);
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_PREVIEW,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW,
                AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
              // await this.SetCommandStates(this.commandStatus.AcceptPreview());
              this.bInPreviewState = true;
            }
          }
        } else {
          this.bInReservedPredictiveState = false;
          if (this.bHasTwoContacts) {
            if (this.bHasOneHeldContact) {
              let previousState: number =
                this.callHistoryManager.GetLastKnownState(call.Handle);
              let bIsCurrentCall = this.callHistoryManager.IsCurrentCall(
                call.Handle
              );
              this.logger.logTrace(
                `${this.cname}.${fname}: IsCurrentCall=${bIsCurrentCall}, PreviousState=${previousState}, Description=${description}`
              );

              if (bIsCurrentCall) {
                this.callHistoryManager.UpdateCurrentCall(
                  call.Handle,
                  AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING
                );
              } else {
                this.callHistoryManager.AddCurrentCall(
                  call.Handle,
                  AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING
                );
              }
              if (this.bDisplayDNISOnAlert) {
                let wstrDNIS = this.GetDNISFromEventProperty(
                  call.CallProperties
                );
                if (wstrDNIS && wstrDNIS != "0") {
                  let wstrAppendDNIS = `${description} - DNIS: ${wstrDNIS}`;
                  await this.RaiseNewWorkItem(
                    call.Handle,
                    wstrAppendDNIS,
                    SCWorkItemMode.SC_WM_INBOUND
                  );
                  await this.RaiseWorkItemStarted(call.Handle, wstrAppendDNIS);
                  this.RaiseAMCSiebelContactEvent(
                    EVENTS_ENUM.EVT_MCIS_ALERTING,
                    call.Handle,
                    AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING,
                    AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                    description,
                    call.CallProperties,
                    this.bHasTwoContacts,
                    curCallState.ChannelType
                  );
                } else {
                  await this.RaiseNewWorkItem(
                    call.Handle,
                    description,
                    SCWorkItemMode.SC_WM_INBOUND
                  );
                  await this.RaiseWorkItemStarted(call.Handle, description);
                }
              } else {
                await this.RaiseNewWorkItem(
                  call.Handle,
                  description,
                  SCWorkItemMode.SC_WM_INBOUND
                );
                if (!this.bSendConnectedAfterAnswer) {
                  await this.RaiseWorkItemStarted(call.Handle, description);
                }
              }
              // await this.SetCommandStates(this.commandStatus.AlertingContact());

              if (!this.bCanAnswer) {
                // await this.SetCommandStates(
                //   this.commandStatus.SetAnswerEnabled(false)
                // );
              }
            }
          } else {
            const bIsCurrentCall = false;
            this.callHistoryManager.IsCurrentCall(call.Handle);
            this.logger.logTrace(
              `${this.cname}.${fname}: IsCurrentCall=${bIsCurrentCall}, Description=${description}`
            );

            if (bIsCurrentCall) {
              this.callHistoryManager.UpdateCurrentCall(
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING
              );
            } else {
              this.callHistoryManager.AddCurrentCall(
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING
              );
            }
            if (this.bDisplayDNISOnAlert) {
              let wstrDNIS = this.GetDNISFromEventProperty(call.CallProperties);
              if (wstrDNIS && wstrDNIS != "0") {
                let wstrAppendDNIS = `${description} - DNIS: ${wstrDNIS}`;
                await this.RaiseNewWorkItem(
                  call.Handle,
                  wstrAppendDNIS,
                  SCWorkItemMode.SC_WM_INBOUND
                );
                await this.RaiseWorkItemStarted(call.Handle, wstrAppendDNIS);
                this.RaiseAMCSiebelContactEvent(
                  EVENTS_ENUM.EVT_MCIS_ALERTING,
                  call.Handle,
                  AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING,
                  AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                  description,
                  call.CallProperties,
                  this.bHasTwoContacts,
                  curCallState.ChannelType
                );
              } else {
                await this.RaiseNewWorkItem(
                  call.Handle,
                  description,
                  SCWorkItemMode.SC_WM_INBOUND
                );
              }
            } else {
              await this.RaiseNewWorkItem(
                call.Handle,
                description,
                SCWorkItemMode.SC_WM_INBOUND
              );
              if (!this.bSendConnectedAfterAnswer) {
                await this.RaiseWorkItemStarted(call.Handle, description);
              }
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_ALERTING,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING,
                AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
            }
            // await this.SetCommandStates(this.commandStatus.AlertingContact());

            if (!this.bCanAnswer) {
              // await this.SetCommandStates(
              //   this.commandStatus.SetAnswerEnabled(false)
              // );
            }
          }
        }
      } else if (call.State == AMC_CONNECTION_STATES.AMC_CSTATE_INITIATED) {
        this.logger.logTrace(
          `${this.cname}.${fname}: Initiated Event. Description=${description}`
        );
        if (currentAgentMode == AGENT_MODES.AGENT_MODE_CONFERENCE_INIT) {
          // await this.SetCommandStates(this.commandStatus.ConferenceConsult());
        } else if (
          currentAgentMode == AGENT_MODES.AGENT_MODE_WARM_TRANSFER_INIT
        ) {
          // await this.SetCommandStates(this.commandStatus.WarmTransferConsult());
        } else {
          // await this.SetCommandStates(this.commandStatus.InitateContact());
        }

        if (!this.callHistoryManager.IsCurrentCall(call.Handle)) {
          // Line 6388
          this.callHistoryManager.AddCurrentCall(call.Handle, call.State);

          await this.RaiseNewWorkItem(
            call.Handle,
            description,
            SCWorkItemMode.SC_WM_OUTBOUND
          );
          await this.RaiseWorkItemStarted(call.Handle, description);
          this.RaiseAMCSiebelContactEvent(
            EVENTS_ENUM.EVT_MCIS_INITIATED,
            call.Handle,
            AMC_CONNECTION_STATES.AMC_CSTATE_INITIATED,
            AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
            description,
            call.CallProperties,
            this.bHasTwoContacts,
            curCallState.ChannelType
          );
          if (
            this.bShowAllCallNotifications &&
            currentAgentMode == AGENT_MODES.AGENT_MODE_DEFAULT
          ) {
            let wstMessage = `Dialing << ${description} >>`;
            await this.SendClientMessage(wstMessage);
          }
        } else {
          const previousState = this.callHistoryManager.GetLastKnownState(
            call.Handle
          );
          if (previousState == AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW) {
            this.bInPreviewState = false;
          }
          this.callHistoryManager.UpdateCurrentCall(call.Handle, call.State);
          const bIsCurrentCall = this.callHistoryManager.IsCurrentCall(
            call.Handle
          );
          this.logger.logTrace(
            `${this.cname}.${fname}: IsCurrentCall=${bIsCurrentCall}, PreviousState=${previousState}, Description=${description}`
          );
          await this.RaiseWorkItemStarted(call.Handle, description);
          this.RaiseAMCSiebelContactEvent(
            EVENTS_ENUM.EVT_MCIS_INITIATED,
            call.Handle,
            AMC_CONNECTION_STATES.AMC_CSTATE_INITIATED,
            AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
            description,
            call.CallProperties,
            this.bHasTwoContacts,
            curCallState.ChannelType
          );
          if (
            this.bShowAllCallNotifications &&
            currentAgentMode == AGENT_MODES.AGENT_MODE_DEFAULT
          ) {
            let wstMessage = `Dialing << ${description} >>`;
            await this.SendClientMessage(wstMessage);
          }
        }
      } else if (
        call.State == AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED ||
        call.State == AMC_CONNECTION_STATES.AMC_CSTATE_FAILED
      ) {
        this.bInReservedPredictiveState = false;
        if (!this.callHistoryManager.IsCurrentCall(call.Handle)) {
          // Line 6432
          this.logger.logTrace(
            `${this.cname}.${fname}: IsCurrentCall=false, Description=${description}`
          );
          if (currentAgentMode == AGENT_MODES.AGENT_MODE_CONFERENCE_INIT) {
            // await this.SetCommandStates(this.commandStatus.ConferenceConsult());
          } else if (
            currentAgentMode == AGENT_MODES.AGENT_MODE_WARM_TRANSFER_INIT
          ) {
            // await this.SetCommandStates(
            //   this.commandStatus.WarmTransferConsult()
            // );
          } else {
            if (
              this.bInPreviewDisposition ||
              this.bInPreviewDirectDisposition
            ) {
              // await this.SetCommandStates(
              //   this.commandStatus.ConnectedOutboundContact()
              // );
            } else {
              this.logger.logTrace(
                `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
              );
              // await this.SetCommandStates(
              //   this.commandStatus.ConnectedContact(
              //     this.bUpdateWorkmodeOnActiveCall,
              //     this.m_WorkMode
              //   )
              // );
            }
          }

          const transferType =
            call.CallProperties[
              CommandParameter.COMMAND_PARAMETER_TRANSFER_TYPE
            ];
          if (transferType) {
            this.callHistoryManager.AddCurrentCall(call.Handle, call.State);
            await this.RaiseNewWorkItem(
              call.Handle,
              description,
              SCWorkItemMode.SC_WM_INBOUND
            );
            await this.RaiseWorkItemStarted(call.Handle, description);
            this.RaiseAMCSiebelContactEvent(
              EVENTS_ENUM.EVT_MCIS_TRANSFERRED,
              call.Handle,
              AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
              AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
              description,
              call.CallProperties,
              this.bHasTwoContacts,
              curCallState.ChannelType
            );
            if (this.bShowAllCallNotifications) {
              await this.SendClientMessage("Consultative Transfer Connected");
            }
          } else if (call.CallParties.length > 1) {
            this.callHistoryManager.AddCurrentCall(call.Handle, call.State);
            await this.RaiseNewWorkItem(
              call.Handle,
              description,
              SCWorkItemMode.SC_WM_INBOUND
            );
            await this.RaiseWorkItemStarted(call.Handle, description);
            this.RaiseAMCSiebelContactEvent(
              EVENTS_ENUM.EVT_MCIS_CONFERENCED,
              call.Handle,
              AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
              AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
              description,
              call.CallProperties,
              this.bHasTwoContacts,
              curCallState.ChannelType
            );
            if (this.bShowAllCallNotifications) {
              await this.SendClientMessage("Conference Connected");
            }
            if (currentAgentMode == AGENT_MODES.AGENT_MODE_CONFERENCE_INIT) {
              //TODO: ResetAll()
              this.agentModeService.ResetAll();
            }
            if (
              this.bInPreviewDisposition ||
              this.bInPreviewDirectDisposition
            ) {
              // await this.SetCommandStates(
              //   this.commandStatus.ConnectedOutboundContact()
              // );
            } else {
              this.logger.logTrace(
                `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
              );
              // await this.SetCommandStates(
              //   this.commandStatus.ConnectedContact(
              //     this.bUpdateWorkmodeOnActiveCall,
              //     this.m_WorkMode
              //   )
              // );
            }
          } else {
            this.callHistoryManager.AddCurrentCall(call.Handle, call.State);

            if (
              this.bSupportOutbound &&
              wstrCallCategory.includes("Outbound") &&
              !description.includes("transfer")
            ) {
              this.logger.logTrace(
                `${this.cname}.${fname}: New call in connected state. This is Predictive (Outbound). `
              );
              let wstrPredictive = `${description} - ${this.wstrPredictiveMessage}`;
              await this.RaiseNewWorkItem(
                call.Handle,
                wstrPredictive,
                SCWorkItemMode.SC_WM_OUTBOUND
              );
              await this.RaiseWorkItemStarted(call.Handle, wstrPredictive);
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_PREDICTIVE,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
              // await this.SetCommandStates(
              //   this.commandStatus.ConnectedOutboundContact()
              // );
            } else {
              await this.RaiseNewWorkItem(
                call.Handle,
                description,
                SCWorkItemMode.SC_WM_INBOUND
              );
              await this.RaiseWorkItemStarted(call.Handle, description);
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_PREDICTIVE,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
              if (
                this.bInPreviewDisposition ||
                this.bInPreviewDirectDisposition
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.ConnectedOutboundContact()
                // );
              } else {
                this.logger.logTrace(
                  `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                );
                // await this.SetCommandStates(
                //   this.commandStatus.ConnectedContact(
                //     this.bUpdateWorkmodeOnActiveCall,
                //     this.m_WorkMode
                //   )
                // );
              }
              if (
                this.bShowAllCallNotifications &&
                !this.bIsConsultCallRetrieved
              ) {
                await this.SendClientMessage("Call Connected");
              }
            }
          }

          if (!this.bCanConference) {
            // Line 6534
            // await this.SetCommandStates(
            //   this.commandStatus.SetConferenceEnabled(false)
            // );
          }
        } else {
          let previousState: number = this.callHistoryManager.GetLastKnownState(
            call.Handle
          );
          if (
            previousState == AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW ||
            this.bInPreviewState
          ) {
            this.bInPreviewState = false;
          }
          let bIsCurrentCall = this.callHistoryManager.IsCurrentCall(
            call.Handle
          );
          this.logger.logTrace(
            `${this.cname}.${fname}: IsCurrentCall=${bIsCurrentCall}, PreviousState=${previousState}, Description=${description}`
          );
          this.callHistoryManager.UpdateCurrentCall(call.Handle, call.State);
          if (
            call.State == AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED ||
            call.State == AMC_CONNECTION_STATES.AMC_CSTATE_FAILED
          ) {
            if (previousState == AMC_CONNECTION_STATES.AMC_CSTATE_ALERTING) {
              if (!this.bSendConnectedAfterAnswer) {
                await this.RaiseWorkItemStarted(call.Handle, description);
              }
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_ANSWERED,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                previousState,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
              if (this.bSendConnectedAfterAnswer) {
                await this.RaiseWorkItemStarted(call.Handle, description);
                this.RaiseAMCSiebelContactEvent(
                  EVENTS_ENUM.EVT_MCIS_CONNECTED,
                  call.Handle,
                  AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                  previousState,
                  description,
                  call.CallProperties,
                  this.bHasTwoContacts,
                  curCallState.ChannelType
                );
              }
              if (
                this.bInPreviewDisposition ||
                this.bInPreviewDirectDisposition
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.ConnectedOutboundContact()
                // );
              } else {
                this.logger.logTrace(
                  `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                );
                // await this.SetCommandStates(
                //   this.commandStatus.ConnectedContact(
                //     this.bUpdateWorkmodeOnActiveCall,
                //     this.m_WorkMode
                //   )
                // );
              }
              if (this.bShowAllCallNotifications) {
                await this.SendClientMessage("Call Connected");
              }
            } else if (previousState == AMC_CONNECTION_STATES.AMC_CSTATE_HELD) {
              this.RaiseWorkItemResumed(call.Handle);
              if (
                currentAgentMode == AGENT_MODES.AGENT_MODE_CONFERENCE_INIT &&
                call.Handle != currentAgentOperationHandle
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.ConferenceConsult()
                // );
              } else if (
                currentAgentMode == AGENT_MODES.AGENT_MODE_WARM_TRANSFER_INIT &&
                call.Handle != currentAgentOperationHandle
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.WarmTransferConsult()
                // );
              } else if (
                currentAgentMode == AGENT_MODES.AGENT_MODE_CONFERENCE_INIT &&
                call.Handle == currentAgentOperationHandle &&
                call.CallParties.length > 1
              ) {
                this.agentModeService.ResetAll();
                this.RaiseAMCSiebelContactEvent(
                  EVENTS_ENUM.EVT_MCIS_CONFERENCED,
                  call.Handle,
                  AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                  previousState,
                  description,
                  call.CallProperties,
                  this.bHasTwoContacts,
                  curCallState.ChannelType
                );
                if (
                  this.bInPreviewDisposition ||
                  this.bInPreviewDirectDisposition
                ) {
                  // await this.SetCommandStates(
                  //   this.commandStatus.ConnectedOutboundContact()
                  // );
                } else {
                  this.logger.logTrace(
                    `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                  );
                  // await this.SetCommandStates(
                  //   this.commandStatus.ConnectedContact(
                  //     this.bUpdateWorkmodeOnActiveCall,
                  //     this.m_WorkMode
                  //   )
                  // );
                }
                if (this.bShowAllCallNotifications) {
                  await this.SendClientMessage("Call Conferenced");
                }
              } else {
                this.RaiseAMCSiebelContactEvent(
                  EVENTS_ENUM.EVT_MCIS_RETRIEVED,
                  call.Handle,
                  AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                  previousState,
                  description,
                  call.CallProperties,
                  this.bHasTwoContacts,
                  curCallState.ChannelType
                );
                if (
                  this.bInPreviewDisposition ||
                  this.bInPreviewDirectDisposition
                ) {
                  // await this.SetCommandStates(
                  //   this.commandStatus.ConnectedOutboundContact()
                  // );
                } else {
                  this.logger.logTrace(
                    `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                  );
                  // await this.SetCommandStates(
                  //   this.commandStatus.ConnectedContact(
                  //     this.bUpdateWorkmodeOnActiveCall,
                  //     this.m_WorkMode
                  //   )
                  // );
                }
                if (this.bShowAllCallNotifications) {
                  await this.SendClientMessage("Call is Retrieved");
                }
              }
            } else if (
              previousState == AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED ||
              previousState == AMC_CONNECTION_STATES.AMC_CSTATE_INITIATED
            ) {
              if (
                currentAgentMode == AGENT_MODES.AGENT_MODE_CONFERENCE_INIT &&
                call.Handle != currentAgentOperationHandle
              ) {
                await this.RaiseWorkItemStarted(call.Handle, description);
                if (call.CallParties.length > 1) {
                  this.agentModeService.ResetAll();
                  this.RaiseAMCSiebelContactEvent(
                    EVENTS_ENUM.EVT_MCIS_CONFERENCED,
                    call.Handle,
                    AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                    previousState,
                    description,
                    call.CallProperties,
                    this.bHasTwoContacts,
                    curCallState.ChannelType
                  );
                  if (this.bShowAllCallNotifications) {
                    await this.SendClientMessage("Call Conferenced");
                  }
                  if (
                    this.bInPreviewDisposition ||
                    this.bInPreviewDirectDisposition
                  ) {
                    // await this.SetCommandStates(
                    //   this.commandStatus.ConnectedOutboundContact()
                    // );
                  } else {
                    this.logger.logTrace(
                      `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                    );
                    // await this.SetCommandStates(
                    //   this.commandStatus.ConnectedContact(
                    //     this.bUpdateWorkmodeOnActiveCall,
                    //     this.m_WorkMode
                    //   )
                    // );
                  }
                } else {
                  // await this.SetCommandStates(
                  //   this.commandStatus.ConferenceConsult()
                  // );
                }
              } else if (
                currentAgentMode == AGENT_MODES.AGENT_MODE_WARM_TRANSFER_INIT &&
                call.Handle != currentAgentOperationHandle
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.WarmTransferConsult()
                // );
              } else {
                if (
                  this.bHasOneHeldContact ||
                  this.bHasTwoParties ||
                  this.bGetRefreshOrderOnTwoCalls
                ) {
                  await this.RaiseWorkItemStarted(call.Handle, description);
                  if (
                    this.bInPreviewDisposition ||
                    this.bInPreviewDirectDisposition
                  ) {
                    // await this.SetCommandStates(
                    //   this.commandStatus.ConnectedOutboundContact()
                    // );
                  } else {
                    this.logger.logTrace(
                      `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                    );
                    // await this.SetCommandStates(
                    //   this.commandStatus.ConnectedContact(
                    //     this.bUpdateWorkmodeOnActiveCall,
                    //     this.m_WorkMode
                    //   )
                    // );
                  }
                  this.RaiseAMCSiebelContactEvent(
                    EVENTS_ENUM.EVT_MCIS_CONNECTED,
                    call.Handle,
                    AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                    previousState,
                    description,
                    call.CallProperties,
                    this.bHasTwoContacts,
                    curCallState.ChannelType
                  );
                  if (
                    this.bShowAllCallNotifications &&
                    !this.bIsConsultCallRetrieved
                  ) {
                    await this.SendClientMessage("Call Connected");
                  }
                } else if (
                  previousState == AMC_CONNECTION_STATES.AMC_CSTATE_INITIATED
                ) {
                  await this.RaiseWorkItemStarted(call.Handle, description);
                  if (
                    this.bInPreviewDisposition ||
                    this.bInPreviewDirectDisposition
                  ) {
                    // await this.SetCommandStates(
                    //   this.commandStatus.ConnectedOutboundContact()
                    // );
                  } else {
                    this.logger.logTrace(
                      `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                    );
                    // await this.SetCommandStates(
                    //   this.commandStatus.ConnectedContact(
                    //     this.bUpdateWorkmodeOnActiveCall,
                    //     this.m_WorkMode
                    //   )
                    // );
                  }

                  this.RaiseAMCSiebelContactEvent(
                    EVENTS_ENUM.EVT_MCIS_CONNECTED,
                    call.Handle,
                    AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                    previousState,
                    description,
                    call.CallProperties,
                    this.bHasTwoContacts,
                    curCallState.ChannelType
                  );
                  if (
                    this
                      .bShowAllCallNotifications /*&& previousState != AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED*/
                  ) {
                    // TODO: review this static check error
                    await this.SendClientMessage("Call Connected");
                  }
                } else if (
                  this.bGetRefreshOrderOnTwoCalls &&
                  !this.bHasTwoContacts
                ) {
                  this.RaiseWorkItemResumed(call.Handle);
                  if (
                    this.bInPreviewDisposition ||
                    this.bInPreviewDirectDisposition
                  ) {
                    // await this.SetCommandStates(
                    //   this.commandStatus.ConnectedOutboundContact()
                    // );
                  } else {
                    this.logger.logTrace(
                      `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                    );
                    // await this.SetCommandStates(
                    //   this.commandStatus.ConnectedContact(
                    //     this.bUpdateWorkmodeOnActiveCall,
                    //     this.m_WorkMode
                    //   )
                    // );
                  }

                  this.RaiseAMCSiebelContactEvent(
                    EVENTS_ENUM.EVT_MCIS_CONNECTED,
                    call.Handle,
                    AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                    previousState,
                    description,
                    call.CallProperties,
                    this.bHasTwoContacts,
                    curCallState.ChannelType
                  );
                  if (
                    this.bShowAllCallNotifications &&
                    previousState != AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED
                  ) {
                    await this.SendClientMessage("Call Connected");
                  }
                }
              }
            } else if (
              previousState == AMC_CONNECTION_STATES.AMC_CSTATE_MAX ||
              previousState == AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW
            ) {
              await this.RaiseWorkItemStarted(call.Handle, description);
              this.logger.logTrace(
                `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
              );
              // await this.SetCommandStates(
              //   this.commandStatus.ConnectedContact(
              //     this.bUpdateWorkmodeOnActiveCall,
              //     this.m_WorkMode
              //   )
              // );
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_CONNECTED,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_CONNECTED,
                previousState,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
              if (this.bShowAllCallNotifications) {
                await this.SendClientMessage("Call Connected");
              }
            }
          }
          if (!this.bCanConference) {
            // await this.SetCommandStates(
            //   this.commandStatus.SetConferenceEnabled(false)
            // );
          }
        }
      } else if (
        call.State == AMC_CONNECTION_STATES.AMC_CSTATE_HELD &&
        !this.bInReservedPredictiveState
      ) {
        if (!this.callHistoryManager.IsCurrentCall(call.Handle)) {
          if (this.bSupportOutbound && this.bInPreviewState) {
            this.logger.logTrace(
              `${this.cname}.${fname}: Unknown Held Call... Add to this.callHistoryManager as preview call`
            );
            this.callHistoryManager.AddCurrentCall(
              call.Handle,
              AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW
            );
            this.logger.logTrace(
              `${this.cname}.${fname}: This is new preview call (Outbound).`
            );
            if (this.wstrPreviewANIFromCADField) {
              if (!PreviewANI) {
                PreviewANI =
                  call.CallProperties[this.wstrPreviewANIFromCADField]; // TODO: Get key-Val from Data Store | line 6778
              }
              this.logger.logTrace(
                `${this.cname}.${fname}: PreviewANI=${PreviewANI}`
              );
              if (PreviewANI) {
                description = PreviewANI;
                wstrPreview = `${description} - ${this.wstrPreviewMessage}`;
                if (!wstrPreview.includes("Seconds")) {
                  let wstrTimeout =
                    call.CallProperties[this.wstrTimedPreviewKeyName]; // TODO: Get Key-Val from Data Store | line 6787
                  if (wstrTimeout) {
                    wstrPreview = `${PreviewANI} - ${wstrTimeout} Seconds ${this.wstrPreviewMessage}`;
                  }
                }

                await this.RaiseNewWorkItem(
                  call.Handle,
                  wstrPreview,
                  SCWorkItemMode.SC_WM_OUTBOUND
                );
                await this.RaiseWorkItemStarted(call.Handle, wstrPreview);
                this.RaiseAMCSiebelContactEvent(
                  EVENTS_ENUM.EVT_MCIS_PREVIEW,
                  call.Handle,
                  AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW,
                  AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                  description,
                  call.CallProperties,
                  this.bHasTwoContacts,
                  curCallState.ChannelType
                );
                // await this.SetCommandStates(this.commandStatus.AcceptPreview());
                this.bInPreviewState = true;
              }
            }
          } else {
            this.logger.logTrace(
              `${this.cname}.${fname}: Unknown Held Call... Add to callHistoryManager as Inbound held call`
            );
            this.callHistoryManager.AddCurrentCall(call.Handle, call.State);

            await this.RaiseWorkItemSuspended(call.Handle);
            this.RaiseAMCSiebelContactEvent(
              EVENTS_ENUM.EVT_MCIS_HELD,
              call.Handle,
              AMC_CONNECTION_STATES.AMC_CSTATE_HELD,
              AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
              description,
              call.CallProperties,
              this.bHasTwoContacts,
              curCallState.ChannelType
            );
            if (
              this.bShowAllCallNotifications &&
              currentAgentMode == AGENT_MODES.AGENT_MODE_DEFAULT
            ) {
              await this.SendClientMessage("Call is On Hold");
            }
            if (
              !(
                currentAgentMode == AGENT_MODES.AGENT_MODE_CONFERENCE_INIT ||
                currentAgentMode == AGENT_MODES.AGENT_MODE_WARM_TRANSFER_INIT ||
                Object.keys(curCallState.Calls).length > 1
              )
            ) {
              if (
                this.bInPreviewDisposition ||
                this.bInPreviewDirectDisposition
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.HeldOutboundContact()
                // );
              } else {
                this.logger.logTrace(
                  `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                );
                // await this.SetCommandStates(
                //   this.commandStatus.HeldContact(
                //     this.bUpdateWorkmodeOnActiveCall,
                //     this.m_WorkMode
                //   )
                // );
              }
            } else if (Object.keys(curCallState.Calls).length == 1) {
              if (
                this.bInPreviewDisposition ||
                this.bInPreviewDirectDisposition
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.HeldOutboundContact()
                // );
              } else {
                this.logger.logTrace(
                  `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                );
                // await this.SetCommandStates(
                //   this.commandStatus.HeldContact(
                //     this.bUpdateWorkmodeOnActiveCall,
                //     this.m_WorkMode
                //   )
                // );
              }
              this.logger.logTrace(
                `${this.cname}.${fname}: Enable 'Unhold' button for Unknown Inbound Held Call because of only one contact here.`
              );
            }
          }
        } else {
          const previousState = this.callHistoryManager.GetLastKnownState(
            call.Handle
          );
          this.callHistoryManager.UpdateCurrentCall(call.Handle, call.State);

          if (this.wstrPreviewANIFromCADField && this.bInPreviewState) {
            if (!PreviewANI) {
              PreviewANI = call.CallProperties[this.wstrPreviewANIFromCADField]; // TODO: Get Key-Val from Data store | line 6854
            }
            this.logger.logTrace(
              `${this.cname}.${fname}: PreviewANI = ${PreviewANI}`
            );
            if (PreviewANI) {
              description = PreviewANI;
              wstrPreview = `${description} - ${this.wstrPreviewMessage}`;
              if (!wstrPreview.includes("Seconds")) {
                let wstrTimeout =
                  call.CallProperties[this.wstrTimedPreviewKeyName]; // TODO: Get Key-Val from data store | line 6863
                if (wstrTimeout) {
                  wstrPreview = `${description} - ${wstrTimeout} Seconds ${this.wstrPreviewMessage}`;
                }
              }
              await this.RaiseWorkItemStarted(call.Handle, wstrPreview);
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_PREVIEW,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_PREVIEW,
                AMC_CONNECTION_STATES.AMC_CSTATE_NULL,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
              // await this.SetCommandStates(this.commandStatus.AcceptPreview());
            }
          }
          if (!(this.bSupportOutbound && this.bInPreviewState)) {
            if (previousState != AMC_CONNECTION_STATES.AMC_CSTATE_HELD) {
              await this.RaiseWorkItemSuspended(call.Handle);
              this.RaiseAMCSiebelContactEvent(
                EVENTS_ENUM.EVT_MCIS_HELD,
                call.Handle,
                AMC_CONNECTION_STATES.AMC_CSTATE_HELD,
                previousState,
                description,
                call.CallProperties,
                this.bHasTwoContacts,
                curCallState.ChannelType
              );
              if (
                this.bInPreviewDisposition ||
                this.bInPreviewDirectDisposition
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.HeldOutboundContact()
                // );
              } else {
                this.logger.logTrace(
                  `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                );
                // await this.SetCommandStates(
                //   this.commandStatus.HeldContact(
                //     this.bUpdateWorkmodeOnActiveCall,
                //     this.m_WorkMode
                //   )
                // );
              }

              if (
                this.bShowAllCallNotifications &&
                currentAgentMode == AGENT_MODES.AGENT_MODE_DEFAULT
              ) {
                await this.SendClientMessage("Call is On Hold");
              }
            }

            if (
              !(
                currentAgentMode == AGENT_MODES.AGENT_MODE_CONFERENCE_INIT ||
                currentAgentMode == AGENT_MODES.AGENT_MODE_WARM_TRANSFER_INIT ||
                Object.keys(curCallState.Calls).length > 1
              )
            ) {
              if (
                this.bInPreviewDisposition ||
                this.bInPreviewDirectDisposition
              ) {
                // await this.SetCommandStates(
                //   this.commandStatus.HeldOutboundContact()
                // );
              } else {
                this.logger.logTrace(
                  `${this.cname}.${fname}: Workmode is ${this.m_WorkMode}`
                );
                // await this.SetCommandStates(
                //   this.commandStatus.HeldContact(
                //     this.bUpdateWorkmodeOnActiveCall,
                //     this.m_WorkMode
                //   )
                // );
              }
            }
          }
        }
      }
    } catch (err) {
      this.logger.logError(
        `${this.cname}.${fname}: Error: ${JSON.stringify(err)}`
      );
    } finally {
      this.logger.logDebug(
        `${this.cname}.${fname}: END [${this.user}] Handle=${curCallState.EventHandle}`
      );
      return retVal;
    }
  }

  public async OnInteractionButtons(interaction: IInteraction) {
    const fname = `${this.cname}.OnInteractionButtons()`;
    try {
      const currentInteractionId = interaction.interactionId.replace(/[\sA-Za-z]*/g, "");
      const currentScenarioId = interaction.scenarioId.replace(/[\sA-Za-z]*/g, "");
      this.loggerService.log(LOG_LEVEL.Debug, fname, 'Setting button states for interaction: ', { interactionId: interaction.interactionId, state: interaction.state });
      this.loggerService.log(LOG_LEVEL.Trace, fname, `Interaction: `, { interaction });
      const currentPresence: IGetPresenceResult = await getPresence();
      const isReady: boolean = currentPresence.presence === Presence.Ready;
      this.loggerService.log(LOG_LEVEL.Trace, fname, `Current Presence: `, { currentPresence, isReady });

      const activeInteractions = this.getActiveInteractionsFromWorkItemMap();
      if (activeInteractions.length > 1) {
        this.loggerService.log(LOG_LEVEL.Debug, fname, `Consult Step: ${activeInteractions.length}`, activeInteractions);
        const otherInteraction = activeInteractions.find((i) => i.interactionId.replace(/[\sA-Za-z]*/g, "") !== currentInteractionId);
        if (otherInteraction != null) {
          const mode = this.agentModeService.GetCurrentAgentMode();
          if (otherInteraction.scenarioId.replace(/[\sA-Za-z]*/g, "") === currentScenarioId) {
            this.loggerService.log(LOG_LEVEL.Trace, fname, `Consult Step: `, { interaction, otherInteraction });
            await this.SetCommandStates(this.commandStatus.InteractionsConsult(mode === AGENT_MODES.AGENT_MODE_WARM_TRANSFER_INIT, this.enableDispositionOnCall));
          } else {
            this.loggerService.log(LOG_LEVEL.Trace, fname, `Unrelated Calls: `, { interaction, otherInteraction });
            await this.SetCommandStates(this.commandStatus.InteractionsUnrelated(this.enableDispositionOnCall));
          }
        } else {
          this.loggerService.log(LOG_LEVEL.Warning, fname, `ActiveInteractions has duplicate interactions: `, activeInteractions);
        }
      } else {
        this.loggerService.log(LOG_LEVEL.Trace, fname, `Single Interaction: `, { interactionId: interaction.interactionId, state: interaction.state });
        switch (interaction.state) {
          case INTERACTION_STATES.Alerting:
            await this.SetCommandStates(this.commandStatus.InteractionAlerting());
            break;
          case INTERACTION_STATES.Connected:
            await this.SetCommandStates(this.commandStatus.InteractionConnected(this.enableDispositionOnCall));
            break;
          case INTERACTION_STATES.Disconnected:
            const enableDisposition =
              activeInteractions.length > 0 ||
              (
                activeInteractions.length === 0 &&
                !this.dispositionSentDisconnect.has(interaction.interactionId)
              );
            await this.SetCommandStates(this.commandStatus.InteractionDisconnected(enableDisposition, isReady));
            break;
          case INTERACTION_STATES.OnHold:
            await this.SetCommandStates(this.commandStatus.InteractionHeld());
            break;
          case INTERACTION_STATES.Initiated:
            await this.SetCommandStates(this.commandStatus.InteractionInitiated());
            break;
          default:
            this.loggerService.log(LOG_LEVEL.Debug, fname, `No action defined for interaction state: ${interaction.state}`);
            break;
        }
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, `Failed to set button states for interaction: ${interaction.interactionId}`, error);
    }
  }

  public async RaiseCleanAllWorkItem(): Promise<SCErrorCode> {
    let retVal = SCErrorCode.SC_EC_OK;
    const fname = `${this.cname}.RaiseCleanAllWorkItem()`;

    this.logger.logTrace(`${fname}: [Begin]`);

    try {
      await this.CleanAllWorkItem();
    } catch (err) {
      retVal = SCErrorCode.SC_EC_CLIENT_INTERFACE_ERR;
      this.logger.logError(
        `${fname}: An excepiton occurred calling CleanAllWorkItem`
      );
    }

    this.logger.logTrace(`${fname}: [End]`);
    return retVal;
  }

  private async RaiseWorkItemReleased(handle: string): Promise<SCErrorCode> {
    let retVal = SCErrorCode.SC_EC_OK;
    const fname = `${this.cname}.RaiseWorkItemReleased()`;

    this.logger.logTrace(`${fname}: [Begin] Handle=${handle}`);

    try {
      if (!handle) {
        this.logger.logError(`${fname}: Handle not provided.`);
        retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
      } else {
        try {
          await this.WorkItemReleased(handle, this.GetDateInSeconds());
        } catch (err) {
          retVal = SCErrorCode.SC_EC_CLIENT_INTERFACE_ERR;
          this.logger.logTrace(
            `${fname}: An exception occurred calling WorkItemReleased`
          );
        }
      }
    } catch (err) {
      retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
      this.logger.logError(`${fname}: Error - ${JSON.stringify(err)}`);
    } finally {
      this.logger.logTrace(`${fname}: [End] Handle=${handle}`);
      return retVal;
    }
  }

  private async RaiseWorkItemStarted(
    handle: string,
    description: string
  ): Promise<SCErrorCode> {
    let retVal = SCErrorCode.SC_EC_OK;
    const fname = `${this.cname}.RaiseWorkItemStarted()`;
    try {
      this.logger.logTrace(
        `${fname}: [Begin] Handle=${handle}, Description=${description}`
      );
      if (!handle || !description) {
        this.logger.logError(
          `${fname}: Handle or Description is zero in length`
        );
        retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
      } else {
        try {
          await this.WorkItemStarted(
            handle,
            handle,
            description,
            this.GetStation(),
            this.GetDateInSeconds()
          );
        } catch (err) {
          retVal = SCErrorCode.SC_EC_CLIENT_INTERFACE_ERR;
          this.logger.logError(
            `${fname}: An exception occurred calling WorkItemStarted`
          );
        }
      }
    } catch (err) {
      this.logger.logError(`${fname}: Error: ${JSON.stringify(err)}`);
      retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
    } finally {
      this.logger.logTrace(`${fname}: END`);
      return retVal;
    }
  }

  private GetStation(): string {
    // TODO: Find a way to return station. User attribute?
    return undefined;
  }

  private async RaiseNewWorkItem(
    handle: string,
    description: string,
    direction: SCWorkItemMode
  ): Promise<SCErrorCode> {
    const fname = `${this.cname}.RaiseNewWorkItem()`;
    let retVal = SCErrorCode.SC_EC_OK;

    this.logger.logTrace(
      `${fname}: [Begin] Handle=${handle}, Description=${description}, Direction=${SCErrorCode[direction]}`
    );
    try {
      if (!handle || !description) {
        this.logger.logError(`${fname}: Handle or Description not provided.`);
        retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
      } else {
        try {
          await this.IndicateNewWorkItem(
            handle,
            handle,
            description,
            direction
          );
        } catch (err) {
          retVal = SCErrorCode.SC_EC_CLIENT_INTERFACE_ERR;
          this.logger.logError(
            `${fname}: An exception occurred calling IndicateNewWorkItem`
          );
        }
      }
    } catch (err) {
      this.logger.logError(`${fname}: Error: ${JSON.stringify(err)}`);
      retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
    } finally {
      this.logger.logTrace(`${fname}: [END]`);
      return retVal;
    }
  }

  private async RaiseWorkItemSuspended(handle: string): Promise<SCErrorCode> {
    const fname = `${this.cname}.RaiseWorkItemSuspended()`;
    let retVal = SCErrorCode.SC_EC_OK;

    this.logger.logTrace(`${fname}: [BEGIN] Handle=${handle}`);
    try {
      if (!handle) {
        this.logger.logError(`${fname}: Handle is not provided.`);
        retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
      } else {
        try {
          await this.WorkItemSuspended(handle);
        } catch (err) {
          retVal = SCErrorCode.SC_EC_CLIENT_INTERFACE_ERR;
          this.logger.logError(
            `${fname}: An exception occurred calling WorkItemSuspended`
          );
        }
      }
    } catch (err) {
      this.logger.logError(`${fname}: Error: ${JSON.stringify(err)}`);
      retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
    } finally {
      this.logger.logTrace(`${fname}: [END]`);
      return retVal;
    }
  }

  private async RaiseAMCSiebelContactEvent(
    eventId: number,
    handle: string,
    callState: number,
    previousCallState: number,
    ANI: string,
    eventProperties: CallProperties,
    hasMultipleContacts: boolean,
    channelType: string
  ): Promise<SCErrorCode> {
    let retVal = SCErrorCode.SC_EC_OK;
    const fname = `${this.cname}.RaiseAMCSiebelContactEvent()`;

    try {
      if (eventId < 0 || eventId >= EVENTS_ENUM.NUM_EVENTS) {
        this.logger.logError(`${fname}: Illegal EventID passed: ${eventId}`);
      } else {
        let eventName = this.EventIdToEventName(eventId);

        this.logger.logTrace(
          `${fname}: [Begin] [${this.user}] EventID=${eventId} (${eventName}), Handle=${handle}, CallState=${callState}, PreviousCallState=${previousCallState}, ANI=${ANI}`
        );
        const nLastRaisedEvent: number =
          this.callHistoryManager.GetLastRaisedEvent(handle);
        this.logger.logTrace(
          `${fname}: [${this.user}] : LastRaisedEventID=${nLastRaisedEvent} (${SupportedEvents[nLastRaisedEvent]})`
        );
        if (nLastRaisedEvent != eventId) {
          this.callHistoryManager.SetLastRaisedEvent(handle, eventId);
        } else {
          retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
          this.logger.logError(
            `${fname}: [End] [${this.user}] : Duplicated EventID=${eventId} (${SupportedEvents[eventId]}) without raising to Siebel`
          );
          return;
        }

        if (
          eventId == EVENTS_ENUM.EVT_MCIS_DROPPED ||
          eventId == EVENTS_ENUM.EVT_MCIS_ALERTING ||
          eventId == EVENTS_ENUM.EVT_MCIS_ANSWERED ||
          eventId == EVENTS_ENUM.EVT_MCIS_CONNECTED ||
          eventId == EVENTS_ENUM.EVT_MCIS_HELD ||
          eventId == EVENTS_ENUM.EVT_MCIS_RETRIEVED ||
          eventId == EVENTS_ENUM.EVT_MCIS_TRANSFERRED ||
          eventId == EVENTS_ENUM.EVT_MCIS_CONFERENCED ||
          eventId == EVENTS_ENUM.EVT_MCIS_TRANSFER_INITIATED ||
          eventId == EVENTS_ENUM.EVT_MCIS_CONFERENCE_INITIATED ||
          eventId == EVENTS_ENUM.EVT_MCIS_INITIATED ||
          eventId == EVENTS_ENUM.EVT_MCIS_PREVIEW ||
          eventId == EVENTS_ENUM.EVT_MCIS_PREDICTIVE ||
          eventId == EVENTS_ENUM.EVT_MCIS_DISPOSITION
        ) {
          const evHandle = handle;
          const evHandle2 = handle;
          const evName = this.EventIdToEventName(eventId);

          const fields = {};
          let numParams: number = Object.keys(eventProperties).length + 4;
          if (eventId == EVENTS_ENUM.EVT_MCIS_INITIATED) {
            numParams += this.makecallData.length;
            this.logger.logTrace(
              `${fname}: makecallData count is ${this.makecallData.length}`
            );
          } else if (
            eventId == EVENTS_ENUM.EVT_MCIS_TRANSFER_INITIATED ||
            eventId == EVENTS_ENUM.EVT_MCIS_CONFERENCE_INITIATED
          ) {
            numParams += this.makecallDataForConsult.length;
            this.logger.logTrace(
              `${fname}: makecallDataForConsult count is ${this.makecallDataForConsult.length}. `
            );
          }

          ++numParams;

          // TrackingID
          fields[CommandParameter.COMMAND_PARAMETER_TRACKINGID] = evHandle2;
          // CurrentState
          fields[CommandParameter.COMMAND_PARAMETER_CURRENT_STATE] =
            callState.toString(10);
          // PreviousState
          fields[CommandParameter.COMMAND_PARAMETER_PREVIOUS_STATE] =
            previousCallState.toString(10);
          //ANI
          fields[CommandParameter.COMMAND_PARAMETER_ANI] = ANI;
          // Mutliple Contacts
          const hasMultipleContactsName =
            CommandParameter.COMMAND_PARAMETER_HASMULTIPLECONTACTS;
          fields[hasMultipleContactsName] = hasMultipleContacts.toString();

          // Copy Key-Value from makecallData
          if (
            eventId == EVENTS_ENUM.EVT_MCIS_INITIATED &&
            this.makecallData.length > 0
          ) {
            this.makecallData.forEach((callData) => {
              fields[callData.Key] = callData.Value;
              this.logger.logTrace(
                `${fname}: Key=${callData.Key}  Value=${callData.Value}`
              );
            });
            this.makecallData = [];
          } else if (
            (eventId == EVENTS_ENUM.EVT_MCIS_TRANSFER_INITIATED ||
              eventId == EVENTS_ENUM.EVT_MCIS_CONFERENCE_INITIATED) &&
            this.makecallDataForConsult.length > 0
          ) {
            this.makecallDataForConsult.forEach((callData) => {
              fields[callData.Key] = callData.Value;
              this.logger.logTrace(
                `${fname}: Key=${callData.Key}  Value=${callData.Value}`
              );
            });
            this.makecallDataForConsult = [];
          }

          // Copy Key-Value from eventProperties
          Object.keys(eventProperties).forEach((key) => {
            if (this.bRemoveCommaBeforeANI) {
              if (key.includes("ANI")) {
                this.logger.logTrace(
                  `${fname}: Skip Key=${key} Value=${eventProperties[key]}. 'ANI' is already added. `
                );
                return;
              }
            }

            fields[key] = eventProperties[key];
          });

          fields['ChannelType'] = channelType;

          try {
            const ret = await this.HandleEvent(evName, fields, false, evHandle);
            this.logger.logTrace(`${fname}: HandleEvent returned ${ret}`);
          } catch (err) {
            retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
            this.logger.logError(`${fname}: HandleEvent raised an exception.`);
          }
        }
      }
    } catch (err) {
      this.logger.logError(`${fname}: Error: ${JSON.stringify(err)}`);
      retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
    } finally {
      this.logger.logTrace(`${fname}: [END]`);
      return retVal;
    }
  }

  private GetDNISFromEventProperty(eventProperties: CallProperties): string {
    const fname = `${this.cname}.GetDNISFromEventProperty()`;
    let retValue = "0";

    this.logger.logTrace(`${fname}: Begin`);
    try {
      const dnisKey = Object.keys(eventProperties).find((key) => {
        key.includes("DNIS");
      });

      if (dnisKey) {
        this.logger.logTrace(
          `${fname}: DNIS Key=${dnisKey} Value=${eventProperties[dnisKey]}`
        );
        retValue = eventProperties[dnisKey];
      } else {
        this.logger.logTrace(`${fname}: DNIS Key not found`);
      }
    } catch (err) {
    } finally {
      this.logger.logTrace(`${fname}: End. returning [${retValue}]`);
      return retValue;
    }
  }

  private async RaiseWorkItemResumed(handle: string): Promise<SCErrorCode> {
    const fname = `${this.cname}.RaiseWorkItemResumed()`;
    let retVal = SCErrorCode.SC_EC_OK;

    this.logger.logTrace(`${fname}: [Begin] Handle=${handle}`);
    try {
      if (!handle) {
        this.logger.logError(`${fname}: Handle not provided.`);
        retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
      } else {
        try {
          await this.WorkItemResumed(handle);
        } catch (err) {
          retVal = SCErrorCode.SC_EC_CLIENT_INTERFACE_ERR;
        }
      }
    } catch (err) {
      retVal = SCErrorCode.SC_EC_DRIVER_SPECIFIC;
      this.logger.logError(`${fname}: Error: ${JSON.stringify(err)}`);
    } finally {
      this.logger.logTrace(`${fname}: End`);
      return retVal;
    }
  }

  public async SendClientMessage(message: string): Promise<void> {
    const fname = `${this.cname}.SendClientMessage()`;

    this.logger.logTrace(
      `${fname}: [Begin] [${this.user}]. Message=${message}`
    );

    try {
      await this.ShowStatusText(message);
    } catch (err) {
      this.logger.logError(
        `${fname}: An exception occurred calling ShowStatusText`
      );
    }
    this.logger.logTrace(`${fname}: [END] [${this.user}]`);
  }

  public async HandleLogin(
    login: boolean,
    reason?: string
    // clntCmdTrackID: string,
    // name: string,
    // stringParam: string,
    // datasetParam: any
  ): Promise<void> {
    const fname = 'HandleLogin';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, fname, `Handling ${login ? 'login' : 'logout'} event.`, { login, reason });
      if(login) {
        await this.SetCommandStates(this.commandStatus.LoggedIn());
      } else {
        if (!this.bHasPendingLogout || this.m_WorkMode === 4) {
          this.bHasLoginCTI = false;
          await this.SetCommandStates(this.commandStatus.LoggedOut());
          if (this.bSupportLogoutReason) {
            await logout(reason);
          } else {
            await logout();
          }
        } else {
          this.SendClientMessage('In Preview service, please select disposition code first or when CTI automatically set to ready state, it will logout.');
          this.bHasPendingLogout = true;
        }
      }
    } catch (error) {
      if (login) {
        this.SendClientMessage('Failed to login. Check that you don\'t have active calls and that your configuration is correct');
      } else {
        if (!this.bInPreviewState) {
          this.SendClientMessage('Failed to logout. Will try to logout again if current call is dropped and CTI automatically set to ready state.');
        }
        this.bHasPendingLogout = true;
      }
      this.loggerService.log(LOG_LEVEL.Error, fname, `Failed to handle ${login ? 'login' : 'logout'} event.`, error);
    }
  }

  public IsReady(): boolean {
    // This function was originally part of the SiebelCommandStatus.cpp file in premise
    if (this.GetCommandStatus(SupportedCommands.SetAgentReady) === SCCommandFlag.SC_CF_NOPARAMSOK) {
      return true;
    }
    return false;
  }

  ///////////////////////////////////////////////////////////////////////////////////
  //                    SiebelService and SiebelDriver functions                   //
  ///////////////////////////////////////////////////////////////////////////////////

  private InvokeCommand(
    clntCmdTrackID: string,
    name: string,
    stringParam: string,
    datasetParam: any
  ): void {
    this.$InvokeCommand.next({
      clntCmdTrackID,
      name,
      stringParam,
      datasetParam,
    });
  }

  private APIVersion: () => void;
  private RequestCommandEventList: (mediaTypeStr: string) => {
    commandList: string[];
    eventList: string[];
  };
  private CreateISCDriverInstance: (
    mediaTypeStr: string,
    languageCode: string,
    connectString: string,
    datasetParams: any
  ) => void;
  private RequestService: (
    driver: ISiebelDriver,
    clntInterface: any,
    connectString: string,
    datasetParams: any
  ) => void;
  private ReleaseISCDriverInstance: (driverHandle: ISiebelDriver) => void;
  private ReleaseISCServiceInstance: () => void;
  private NotifyEventHandlingFinished: (
    trackingID: string,
    result: boolean
  ) => void;
  private InvokeCommandEx: (
    clntCmdTrackID: string,
    commandType: SCCommandTypeEx,
    datasetParams: any
  ) => void;
  private AcceptWorkItem: (trackingID: string) => void;
  private ReleaseWorkItem: (trackingID: string) => void;
  private SuspendWorkItem: (trackingID: string) => void;
  private ResumeWorkItem: (trackingID: string) => void;
  private HandleQueuedWorkItem: (
    name: string,
    fields: any,
    trackingID: string
  ) => void;
  private RevokeQueuedWorkItem: (trackingID: string) => void;

  ///////////////////////////////////////////////////////////////////////////////////
  //                          private helper functions                             //
  ///////////////////////////////////////////////////////////////////////////////////

  private getPhone(interaction: IInteraction): string {
    if (interaction?.details?.fields?.Phone?.Value) {
      return interaction.details.fields.Phone.Value;
    }
    if (interaction?.details?.fields?.phone?.Value) {
      return interaction.details.fields.phone.Value;
    }
    return;
  }

  private GetDateInSeconds(): number {
    return Math.floor(new Date().getTime() / 1000);
  }

  private processInteraction(interaction) {
    // Repair interaction if CTI misrepresents it
    // if (interaction.state == "Alerting" && interaction.direction.toLowerCase() == 'outbound') {
    //     interaction.state = "Initiated";
    // }

    if (!this.scenarioMap[interaction.scenarioId]) {
      this.scenarioMap[interaction.scenarioId] = {};
    }
    if (!this.scenarioMap[interaction.scenarioId][interaction.interactionId]) {
      this.scenarioMap[interaction.scenarioId][interaction.interactionId] =
        interaction;
    }
  }

  private updateInteractionMap(interaction) {
    this.scenarioMap[interaction.scenarioId][interaction.interactionId] =
      interaction;
  }

  private getDriverEvent(interaction) {
    // Determine if we have seen this interaction before
    if (this.scenarioMap[interaction.scenarioId]?.[interaction.interactionId]) {
      let prevInteraction =
        this.scenarioMap[interaction.scenarioId][interaction.interactionId];

      // Nullish coalescing, return normal state-to-driver-event if there is no mapping for previous-current state pair
      return (
        this.StateHistory2EventMap[prevInteraction.state][interaction.state] ??
        this.CallState2EventMap[interaction.state]
      );
    }
    // If we haven't seen the interaction before, we can only consider the current state.

    return this.CallState2EventMap[interaction.state];
  }

  private readSiebelConfig() {
    const functionName = "readSiebelConfig";
    this.user = <string>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_USERNAME
      )
    );
    this.bShowAllCallNotifications = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_SHOWALLCALLNOTIFICATION
      )
    );
    this.bRefreshLargeCallHandleFirst = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_REFRESHLARGEHANDLEFIRST
      )
    );
    this.bSendDropEventFirst = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_SENDDROPEVENTFIRST
      )
    );
    this.bSupportOutbound = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_SUPPORTOUTBOUND
      )
    );
    this.bSkipPreviewDisposition = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_SKIP_PREVIEW_DISPOSITION
      )
    );
    this.bSkipCallDisposition = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_SKIP_CALL_DISPOSITION
      )
    );
    this.bDisableWorkmodeDuringCalls = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_DISABLEWORKMODEDURINGCALLS
      )
    );
    this.bLetDropEventCleanWorkItems = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_LETDROPEVENTCLEANWORKITEMS
      )
    );
    this.getRefreshOrderOnTwoCalls = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_GETREFRESHORDERONTWOCALLS
      )
    );
    this.bUpdateWorkmodeOnActiveCall = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_UPDATEWORKONACTIVECALL
      )
    );
    this.wstrPreviewMessage = <string>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_PREVIEW
      )
    );
    this.wstrTimedPreviewKeyName = <string>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_TIMED_PREVIEW_KEY_Name
      )
    );
    this.wstrPreviewANIFromCADField = <string>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_PREVIEWANI_FROM_CADFIELD
      )
    );
    this.bDisplayDNISOnAlert = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_DISPAYDNISONALERT
      )
    );
    this.bSendConnectedAfterAnswer = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_SENDCONNECTED_AFTERANSWER
      )
    );
    this.wstrPredictiveMessage = <string>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_PREDICTIVE
      )
    );
    this.bGetRefreshOrderOnTwoCalls = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_GETREFRESHORDERONTWOCALLS
      )
    );
    this.bRemoveCommaBeforeANI = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_REMOVECOMMABEFOREANI
      )
    );
    this.bAutoDisposition = <boolean>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_USEAUTODISPOSITION
      )
    );

    this.wstrAgentDefaultStateOnLogin = <string>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_LOGINSTATE
      )
    );

    const supportLogoutReason: string = <string>(this.siebelConfig.getServiceSetting(
      ServiceSettingsKey.SERVICE_SETTINGS_KEY_SUPPORTLOGOUTREASON
    ));
    this.bSupportLogoutReason = <boolean>(supportLogoutReason.toString().toLowerCase() === "true");

    const autoLogout = <string>(this.siebelConfig.getServiceSetting(
      ServiceSettingsKey.SERVICE_SETTINGS_KEY_AUTOLOGOUT
    ));
    this.bAutoLogout = <boolean>(autoLogout.toLowerCase() === "true");
    //! Nothing to implement for autoLogout since the CTI will have to handle it on its own

    const ignoreLogin = <string>(this.siebelConfig.getServiceSetting(
      ServiceSettingsKey.SERVICE_SETTINGS_KEY_IGNORE_LOGIN
    ));
    this.bIgnoreLogin = <boolean>(ignoreLogin.toLowerCase() === "true");

    this.wstrLogoutReasonKey = <string>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_REASONKEY
      )
    );

    const useWorkmodeButtonForACW = <string>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_USEWORKMODE_FORACW
      )
    );
    this.bUseWorkmodeButtonForACW = <boolean>(useWorkmodeButtonForACW.toLowerCase() === "true");

    const blockEventsDuringLogout = <string>(
      this.siebelConfig.getServiceSetting(
        ServiceSettingsKey.SERVICE_SETTINGS_KEY_BLOCKEVENTSDURINGLOGOUT
      )
    );
    this.bBlockEventsDuringLogout = <boolean>(blockEventsDuringLogout.toLowerCase() === "true");
  }

  public logDefFileConfigs() {
    const functionName = 'logDefFileConfigs';
    this.loggerService.log(LOG_LEVEL.Debug, functionName, 'Def file configurations: ', {
      bShowAllCallNotifications: this.bShowAllCallNotifications,
      bRefreshLargeCallHandleFirst: this.bRefreshLargeCallHandleFirst,
      bSendDropEventFirst: this.bSendDropEventFirst,
      bSupportOutbound: this.bSupportOutbound,
      bSkipPreviewDisposition: this.bSkipPreviewDisposition,
      bSkipCallDisposition: this.bSkipCallDisposition,
      bDisableWorkmodeDuringCalls: this.bDisableWorkmodeDuringCalls,
      bLetDropEventCleanWorkItems: this.bLetDropEventCleanWorkItems,
      getRefreshOrderOnTwoCalls: this.getRefreshOrderOnTwoCalls,
      bUpdateWorkmodeOnActiveCall: this.bUpdateWorkmodeOnActiveCall,
      wstrPreviewMessage: this.wstrPreviewMessage,
      wstrTimedPreviewKeyName: this.wstrTimedPreviewKeyName,
      wstrPreviewANIFromCADField: this.wstrPreviewANIFromCADField,
      bDisplayDNISOnAlert: this.bDisplayDNISOnAlert,
      bSendConnectedAfterAnswer: this.bSendConnectedAfterAnswer,
      wstrPredictiveMessage: this.wstrPredictiveMessage,
      bGetRefreshOrderOnTwoCalls: this.bGetRefreshOrderOnTwoCalls,
      bRemoveCommaBeforeANI: this.bRemoveCommaBeforeANI,
      bAutoDisposition: this.bAutoDisposition,
      wstrAgentDefaultStateOnLogin: this.wstrAgentDefaultStateOnLogin,
      bSupportLogoutReason: this.bSupportLogoutReason,
      bAutoLogout: this.bAutoLogout,
      bIgnoreLogin: this.bIgnoreLogin,
      wstrLogoutReasonKey: this.wstrLogoutReasonKey,
      bUserWorkmodeButtonForACW: this.bUseWorkmodeButtonForACW,
      bBlockEventsDuringLogout: this.bBlockEventsDuringLogout
    });
  }


  private EventIdToEventName(eventId: EVENTS_ENUM): SupportedEvents {
    let ret: SupportedEvents;
    switch (eventId) {
      case EVENTS_ENUM.EVT_MCIS_ALERTING:
        ret = SupportedEvents.MCISAlerting;
        break;
      case EVENTS_ENUM.EVT_MCIS_ANSWERED:
        ret = SupportedEvents.MCISAnswered;
        break;
      case EVENTS_ENUM.EVT_MCIS_CONNECTED:
        ret = SupportedEvents.MCISConnected;
        break;
      case EVENTS_ENUM.EVT_MCIS_HELD:
        ret = SupportedEvents.MCISHeld;
        break;
      case EVENTS_ENUM.EVT_MCIS_DROPPED:
        ret = SupportedEvents.MCISDropped;
        break;
      case EVENTS_ENUM.EVT_MCIS_RETRIEVED:
        ret = SupportedEvents.MCISRetrieved;
        break;
      case EVENTS_ENUM.EVT_MCIS_TRANSFERRED:
        ret = SupportedEvents.MCISTransferred;
        break;
      case EVENTS_ENUM.EVT_MCIS_CONFERENCED:
        ret = SupportedEvents.MCISConferenced;
        break;
      case EVENTS_ENUM.EVT_MCIS_TRANSFER_INITIATED:
        ret = SupportedEvents.MCISTransferInitiated;
        break;
      case EVENTS_ENUM.EVT_MCIS_CONFERENCE_INITIATED:
        ret = SupportedEvents.MCISConferenceInitiated;
        break;
      case EVENTS_ENUM.EVT_MCIS_INITIATED:
        ret = SupportedEvents.MCISInitiated;
        break;
      case EVENTS_ENUM.EVT_MCIS_DISPOSITION:
        ret = SupportedEvents.MCISDisposition;
        break;
      case EVENTS_ENUM.EVT_MCIS_PREVIEW:
        ret = SupportedEvents.MCISPreview;
        break;
      case EVENTS_ENUM.EVT_MCIS_PREDICTIVE:
        ret = SupportedEvents.MCISPredictive;
        break;
      default:
        throw new Error("Unrecognized Event ID");
    }

    return ret;
  }
}
