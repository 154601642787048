import { ICadDisplayConfig } from '../Model/ICadDisplayConfig';

export function getCadDisplayConfig(config): ICadDisplayConfig {
  const cadDisplayInfo: ICadDisplayConfig = {DisplayCad : false, CadFields : {}};

  if (config && config.CADDisplay) {
    if (
      config.CADDisplay.variables &&
      config.CADDisplay.variables.DisplayCAD &&
      config.CADDisplay.variables.DisplayKeyList
    ) {
      cadDisplayInfo.DisplayCad = config.CADDisplay.variables.DisplayCAD;
      cadDisplayInfo.CadFields = config.CADDisplay.variables.DisplayKeyList;
    }
  }
  return cadDisplayInfo;
}
