export enum AMC_CONNECTION_STATES {
  AMC_CSTATE_MIN = -1,
  AMC_CSTATE_NULL = 0,
  AMC_CSTATE_INITIATED = 1,
  AMC_CSTATE_ALERTING = 2,
	AMC_CSTATE_CONNECTED = 3,
	AMC_CSTATE_HELD = 4,
	AMC_CSTATE_QUEUED = 5,
	AMC_CSTATE_FAILED = 6,
	AMC_CSTATE_OFFERED = 7,
	AMC_CSTATE_DROPPED = 8,
	AMC_CSTATE_HOLDING = 9,
  AMC_CSTATE_MAX = 10,
	AMC_CSTATE_PREVIEW = 10,
	AMC_CSTATE_MIN_EMAIL = 11,
	AMC_CSTATE_DRAFT = 12,
	AMC_CSTATE_SENT = 13,
	AMC_CSTATE_MAX_EMAIL = 14
};
