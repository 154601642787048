import {
  Component,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import * as api from '@amc-technology/davinci-api';
import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity-dynamics.component.html',
  styleUrls: ['./activity-dynamics.component.css']
})
export class ActivityComponent {
  @Input() scenarioId: string;
  @Input() quickCommentList: string[];
  @Input() isAutoSave: boolean;
  @Input() quickCommentOptionRequiredCadArray: any;
  @Output() saveActivity: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeNotify: EventEmitter<string> = new EventEmitter<string>();

  collapseToggle: boolean;

  constructor(private loggerService: LoggerService, protected storageService: StorageService) {
    this.collapseToggle = true;
  }

  protected expandAndCollapse() {
    this.collapseToggle = !this.collapseToggle;
  }

  protected expandCollapsekey(event: KeyboardEvent) {

    if (event.key === 'Enter') {
      this.expandAndCollapse();
    }
  }

  protected loadText(comment: string) {
    this.loggerService.logger.logTrace('Siebel - Activity : START : Load Quick Comments. Input : ' + comment);
    try {
      let descriptionToSet = this.quickCommentList[comment];
      if (this.quickCommentOptionRequiredCadArray[comment]) {
        // This means the option is configured to accept CAD Automatically
        // Loop through quickCommentOptionRequiredCadArray and replace {{cad}} with the cad coming from channel app
        let cadFields = {};
        if (this.storageService.activityList[this.scenarioId]) {
          cadFields = this.storageService.scenarioToCADMap[this.scenarioId];
        }
        for (let i = 0; i < this.quickCommentOptionRequiredCadArray[comment].length; i++) {
          let keyToCheckIfCADExists = this.quickCommentOptionRequiredCadArray[comment][i];
          const stringToBeReplaced = this.quickCommentOptionRequiredCadArray[comment][i];
          keyToCheckIfCADExists = keyToCheckIfCADExists.replace('{{', '');
          keyToCheckIfCADExists = keyToCheckIfCADExists.replace('}}', '');
          if (cadFields[keyToCheckIfCADExists]) {
            descriptionToSet = descriptionToSet.replace(stringToBeReplaced, cadFields[keyToCheckIfCADExists].Value);
          }
        }
      }
      if (!this.storageService.getDescription()) {
        this.storageService.setDescription(descriptionToSet, this.scenarioId);
      } else {
        this.storageService.setDescription(this.storageService.getDescription() + '\n' + descriptionToSet,
          this.scenarioId);
      }
      this.storageService.compareActivityFields(this.scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Activity : ERROR : Load Quick Comments. Input : '
      + comment + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Activity : END : Load Quick Comments. Input : ' + comment);
  }

  protected isChangesUnSaved(scenarioId: string): boolean {
    const activity = this.storageService.getActivity();
    this.storageService.activityList[scenarioId].IsUnSaved = (activity.IsUnSaved || (!activity.ActivityId && !this.isAutoSave));
    return this.storageService.activityList[scenarioId].IsUnSaved;
  }

  protected submitActivity(scenarioId: string) {
    this.loggerService.logger.logTrace('Siebel - Activity : START : Submit Activity. Scenario ID : ' + scenarioId);
    try {
      this.storageService.activityList[scenarioId].IsProcessing = true;
      this.saveActivity.emit(scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Activity : ERROR : Submit Activity. Scenario ID : '
        + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Activity : END : Submit Activity. Scenario ID : ' + scenarioId);
  }

  protected onNameSelectChange(event: any) {
    this.loggerService.logger.logTrace('Siebel - Activity : START : On Name Change. Input : ' + JSON.stringify(event));
    try {
      this.storageService.UpdateWhoObjectSelectionChange(event.currentTarget.value, this.scenarioId);
      this.storageService.compareActivityFields(this.scenarioId);

      const changeEvent = {
        type: 'onNameSelectChange',
        value: event.currentTarget.value,
        scenarioID: this.scenarioId
      };
      this.changeNotify.emit(JSON.stringify(changeEvent));
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Activity : ERROR : On Name Change. Input : '
        + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Activity : END : On Name Change. Input : ' + JSON.stringify(event));
  }

  protected onRelatedToChange(event: any) {
    this.loggerService.logger.logTrace('Siebel - Activity : START : On Related To Change. Input : ' + JSON.stringify(event));
    try {
      this.storageService.UpdateWhatObjectSelectionChange(event.currentTarget.value, this.scenarioId);
      this.storageService.compareActivityFields(this.scenarioId);

      const changeEvent = {
        type: 'onRelatedToChange',
        value: event.currentTarget.value,
        scenarioID: this.scenarioId
      };
      this.changeNotify.emit(JSON.stringify(changeEvent));
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Activity : ERROR : On Related To Change. Input : '
        + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Activity : END : On Related To Change. Input : ' + JSON.stringify(event));
  }

  protected onSubjectChange(event: any) {
    this.loggerService.logger.logTrace('Siebel - Activity : START : On Subject Change. Input : ' + JSON.stringify(event));
    try {
      if (event.type === 'keyup') {
        this.storageService.activityList[this.scenarioId].IsSubjectChanged = true;
      }
      if (event.type === 'keyup' && this.storageService.activityList[this.scenarioId].IsUnSaved) {
        return;
      }
      this.storageService.setSubject(event.srcElement.value, this.scenarioId);
      this.storageService.compareActivityFields(this.scenarioId);

      const changeEvent = {
        type: 'onSubjectChange',
        value: event.srcElement.value,
        scenarioID: this.scenarioId
      };
      this.changeNotify.emit(JSON.stringify(changeEvent));
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Activity : ERROR : On Subject Change. Input : '
        + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Activity : END : On Subject Change. Input : ' + JSON.stringify(event));
  }

  protected onCallNotesChange(event: any) {
    this.loggerService.logger.logTrace('Siebel - Activity : START : On Call Notes Change. Input : ' + JSON.stringify(event));
    try {
      if (event.type === 'keyup' && this.storageService.activityList[this.scenarioId].IsUnSaved) {
        return;
      }
      this.storageService.setDescription(event.srcElement.value.trim(), this.scenarioId);
      this.storageService.compareActivityFields(this.scenarioId);

      const changeEvent = {
        type: 'onCallNotesChange',
        value: event.srcElement.value.trim(),
        scenarioID: this.scenarioId
      };
      this.changeNotify.emit(JSON.stringify(changeEvent));
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Activity : ERROR : On Call Notes Change. Input : '
        + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Activity : END : On Call Notes Change. Input : ' + JSON.stringify(event));
  }
}
