export enum CommandParameter {
  COMMAND_PARAMETER_AGENTID = 'AgentId',
  COMMAND_PARAMETER_AGENTPIN = 'AgentPin',
  COMMAND_PARAMETER_ACDQUEUE = 'ACDQueue',
  COMMAND_PARAMETER_TRACKINGID = 'TrackingID',
  COMMAND_PARAMETER_PHONENUMBER = 'PhoneNumber',
  COMMAND_PARAMETER_CURRENT_STATE = 'CurrentState',
  COMMAND_PARAMETER_PREVIOUS_STATE = 'PreviousState',
  COMMAND_PARAMETER_ANI = 'ANI',
  COMMAND_PARAMETER_HASMULTIPLECONTACTS = 'HasMultipleContacts',
  COMMAND_PARAMETER_TRANSFER_TYPE = 'TransferType',
  COMMAND_PARAMETER_TRANSFER_TYPE_WARM = 'Warm',
  COMMAND_PARAMETER_TRANSFER_TYPE_BLIND = 'Blind',
  COMMAND_PARAMETER_REASON = 'Reason',
  COMMAND_PARAMETER_CODE = 'Code',
  COMMAND_PARAMETER_ALL_WORKTOPS = '*',
  COMMAND_PARAMETER_CALL_NOTIFY_TEXT = 'CallNotifyText',
  COMMAND_PARAMETER_NEWHANDLE = 'NewHandle',
  COMMAND_PARAMETER_MESSAGE = 'Message',
  COMMAND_PARAMETER_INSTANCE_NAME = 'InstanceName',
  COMMAND_PARAMETER_DTMF_STRING = 'DTMFString',
  COMMAND_PARAMETER_TONE_DURATION = 'ToneDuration',
  COMMAND_PARAMETER_PAUSE_DURATION = 'PauseDuration'
}

export type CommandParameters = {
  [key in CommandParameter]: any;
};

export function DefaultCommandParameters(): CommandParameters {
  const parameters: CommandParameters = {
    [CommandParameter.COMMAND_PARAMETER_AGENTID]: '',
    [CommandParameter.COMMAND_PARAMETER_AGENTPIN]: '',
    [CommandParameter.COMMAND_PARAMETER_ACDQUEUE]: '99999',
    [CommandParameter.COMMAND_PARAMETER_TRACKINGID]: '',
    [CommandParameter.COMMAND_PARAMETER_PHONENUMBER]: '',
    [CommandParameter.COMMAND_PARAMETER_CURRENT_STATE]: '',
    [CommandParameter.COMMAND_PARAMETER_PREVIOUS_STATE]: '',
    [CommandParameter.COMMAND_PARAMETER_ANI]: '',
    [CommandParameter.COMMAND_PARAMETER_HASMULTIPLECONTACTS]: '',
    [CommandParameter.COMMAND_PARAMETER_TRANSFER_TYPE]: '',
    [CommandParameter.COMMAND_PARAMETER_TRANSFER_TYPE_WARM]: 'Warm',
    [CommandParameter.COMMAND_PARAMETER_TRANSFER_TYPE_BLIND]: 'Blind',
    [CommandParameter.COMMAND_PARAMETER_REASON]: '',
    [CommandParameter.COMMAND_PARAMETER_CODE]: '',
    [CommandParameter.COMMAND_PARAMETER_ALL_WORKTOPS]: '*',
    [CommandParameter.COMMAND_PARAMETER_CALL_NOTIFY_TEXT]: '',
    [CommandParameter.COMMAND_PARAMETER_NEWHANDLE]: 'NewHandle',
    [CommandParameter.COMMAND_PARAMETER_MESSAGE]: 'Message',
    [CommandParameter.COMMAND_PARAMETER_INSTANCE_NAME]: 'InstanceName',
    [CommandParameter.COMMAND_PARAMETER_DTMF_STRING]: '',
    [CommandParameter.COMMAND_PARAMETER_TONE_DURATION]: 0,
    [CommandParameter.COMMAND_PARAMETER_PAUSE_DURATION]: 0
  };
  return parameters;
}
