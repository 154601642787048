import { ConfigurationService } from './../configuration.service';
import { Component, Input, EventEmitter, Output } from '@angular/core';

import { StorageService } from '../storage.service';
import { LoggerService } from '../logger.service';
import { initializeComplete }from '@amc-technology/davinci-api';

@Component({
  selector: 'app-recentactivites',
  templateUrl: './recentactivites.component.html',
  styleUrls: ['./recentactivites.component.css']
})
export class RecentactivitesComponent {
  @Input() quickCommentList: string[];
  @Output() saveActivity: EventEmitter<string> = new EventEmitter<string>();
  @Output() getRecentWorkItem: EventEmitter<string> = new EventEmitter<string>();
  @Output() screenpopWorkItem: EventEmitter<object> = new EventEmitter();

  collapseToggle: boolean;
  config: any;

  constructor(private loggerService: LoggerService, protected storageService: StorageService, public ConfigurationService: ConfigurationService) {
    this.collapseToggle = true;
  }

  async ngOnInit() {
    await initializeComplete().then((configReturn) => {
      this.config = configReturn;
    });
  }

  protected submitActivity(scenarioId: string) {
    this.loggerService.logger.logTrace('Siebel - Recent Activity : START : Submit Activity. Scenario ID : ' + scenarioId);
    try {
    this.storageService.activityList[scenarioId].IsProcessing = true;
    this.saveActivity.emit(scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Recent Activity : ERROR : Submit Activity. Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Recent Activity : END : Submit Activity. Scenario ID : ' + scenarioId);
  }

  protected retrieveActivity(scenarioId: string) {
    if (!this.config['variables'].hasOwnProperty('SyncRecentActivitiesWithCRM')  || this.config['variables']['SyncRecentActivitiesWithCRM'] === true) {
      this.loggerService.logger.logTrace('Siebel - Recent Activity : START : Retrieve Activity. Scenario ID : ' + scenarioId);
      try {
        this.getRecentWorkItem.emit(scenarioId);
      } catch (error) {
        this.loggerService.logger.logError('Siebel - Recent Activity : ERROR : Retrieve Activity. Scenario ID : '
        + scenarioId + '. Error Information : ' + JSON.stringify(error));
      }
      this.loggerService.logger.logTrace('Siebel - Recent Activity : END : Retrieve Activity. Scenario ID : ' + scenarioId);
    }
    else{
      this.storageService.activityList[scenarioId].IsRecentWorkItemLoading = false;
        }
  }

  protected openActivity(scenarioId: string) {
    this.loggerService.logger.logTrace('Siebel - Recent Activity : START : Open Activity. Scenario ID : ' + scenarioId);
    try {
      const recordItem: object = {
        id: this.storageService.activityList[scenarioId].ActivityId,
        type: 'phonecall'
      };
      this.screenpopWorkItem.emit(recordItem);
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Recent Activity : ERROR : Open Activity. Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Recent Activity : END : Open Activity. Scenario ID : ' + scenarioId);
  }

  protected expandAndCollapse() {
    this.collapseToggle = !this.collapseToggle;
  }

  protected expandCollapsekey(event: KeyboardEvent) {

    if (event.key === 'Enter') {
      this.expandAndCollapse();
    }
  }

  protected expandAndCollapseRecentActivity(isExpand: boolean, scenarioId: string) {
    try {
      this.loggerService.logger.logTrace('Siebel - Recent Activity : START : Expand/Collapse Activity. Scenario ID : '
      + scenarioId + ', IsExpand : ' + isExpand);
      if (isExpand) {
        this.storageService.workingRecentScenarioId = scenarioId;
        if (!this.storageService.activityList[scenarioId].IsUnSaved) {
          this.storageService.activityList[scenarioId].IsRecentWorkItemLoading = true;
          this.retrieveActivity(scenarioId);
        }
      } else {
        this.storageService.workingRecentScenarioId = null;
      }
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Recent Activity : ERROR : Expand/Collapse Activity. Scenario ID : '
      + scenarioId + ', IsExpand : ' + isExpand + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Recent Activity : END : Expand/Collapse Activity. Scenario ID : '
    + scenarioId + ', IsExpand : ' + isExpand);
  }

  protected expandCollapsekeyRecentActivity(event: KeyboardEvent, scenarioId: string ) {
    if (event.key === 'Enter') {
      this.expandAndCollapseRecentActivity(true, scenarioId);
    }
  }

  protected onNameSelectChange(event: any) {
    this.loggerService.logger.logTrace('Siebel - Recent Activity : START : On Name Change. Input : ' + JSON.stringify(event));
    try {
      this.storageService.UpdateWhoObjectSelectionChange(event.currentTarget.value, this.storageService.workingRecentScenarioId);
      this.storageService.compareActivityFields(this.storageService.workingRecentScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Recent Activity : ERROR : On Name Change. Input : '
      + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Recent Activity : END : On Name Change. Input : ' + JSON.stringify(event));
  }
  protected onRelatedToChange(event: any) {
    this.loggerService.logger.logTrace('Siebel - Recent Activity : START : On Related To Change. Input : ' + JSON.stringify(event));
    try {
      this.storageService.UpdateWhatObjectSelectionChange(event.currentTarget.value, this.storageService.workingRecentScenarioId);
      this.storageService.compareActivityFields(this.storageService.workingRecentScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Recent Activity : ERROR : On Related To Change. Input : '
      + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Recent Activity : END : On Related To Change. Input : ' + JSON.stringify(event));
  }
  protected onSubjectChange(event: any) {
    this.loggerService.logger.logTrace('Siebel - Recent Activity : START : On Subject Change. Input : ' + JSON.stringify(event));
    try {
      if (event.type === 'keyup' && this.storageService.activityList[this.storageService.workingRecentScenarioId].IsUnSaved) {
        return;
      }
      this.storageService.setSubject(event.srcElement.value, this.storageService.workingRecentScenarioId);
      this.storageService.compareActivityFields(this.storageService.workingRecentScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Recent Activity : ERROR : On Subject Change. Input : '
      + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Recent Activity : END : On Subject Change. Input : ' + JSON.stringify(event));
  }
  protected onCallNotesChange(event: any) {
    this.loggerService.logger.logTrace('Siebel - Recent Activity : START : On Call Notes Change. Input : ' + JSON.stringify(event));
    try {
      if (event.type === 'keyup' && this.storageService.activityList[this.storageService.workingRecentScenarioId].IsUnSaved) {
        return;
      }
      this.storageService.setDescription(event.srcElement.value.trim(), this.storageService.workingRecentScenarioId);
      this.storageService.compareActivityFields(this.storageService.workingRecentScenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Recent Activity : ERROR : On Call Notes Change. Input : '
      + JSON.stringify(event) + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Recent Activity : END : On Call Notes Change. Input : ' + JSON.stringify(event));
  }

  protected loadText(comment: string, scenarioId: string) {
    this.loggerService.logger.logTrace('Siebel - Recent Activity : START : Load Quick Comments. Input : ' + comment);
    try {
      const descriptionToSet = this.quickCommentList[comment];
      const descriptionValue = this.storageService.activityList[scenarioId].Description;
      if (!descriptionValue) {
        this.storageService.setDescription(this.quickCommentList[comment], scenarioId);
      } else {
        this.storageService.setDescription(descriptionValue + '\n' + descriptionToSet, scenarioId);
      }
      this.storageService.compareActivityFields(scenarioId);
    } catch (error) {
      this.loggerService.logger.logError('Siebel - Recent Activity : ERROR : Load Quick Comments. Input : '
      + comment + '. Error Information : ' + JSON.stringify(error));
    }
    this.loggerService.logger.logTrace('Siebel - Recent Activity : END : Load Quick Comments. Input : ' + comment);
  }

}
