export enum SCErrorCode {
  SC_EC_OK = 0,
  SC_EC_ERROR = 1,
  SC_EC_CMD_NOT_SUPPORTED = 2,
  SC_EC_MEDIA_TYPE_NOT_SUPPORTED = 3,
  SC_EC_INVALID_HANDLE = 4,
  SC_EC_OUT_OF_MEMORY = 5,
  SC_EC_NETWORK_ERROR = 6,
  SC_EC_LIB_LOAD_ERR = 7,
  SC_EC_FUNC_NOT_RESOLVED = 8,
  SC_EC_DRIVER_CREATION_ERR = 9,
  SC_EC_DRIVER_RELEASE_ERR = 10,
  SC_EC_SERVICE_CREATION_ERR = 11,
  SC_EC_SERVICE_RELEASE_ERR = 12,
  SC_EC_INVALID_ITEM_TRACKING_ID = 13,
  SC_EC_CLIENT_INTERFACE_ERR = 14,
  SC_EC_SENDMSG_FAILED_RETRY = 15,
  SC_EC_IMPOBJ_CREATE_ERR = 16,
  SC_EC_INVALID_LICENSE = 17,
  SC_EC_WORK_ITEM_WRONG_STATE = 18,
  SC_EC_DRIVER_SPECIFIC = 1000
}
