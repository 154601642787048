export class CallHistory {
  private lastKnownState: number;
  private nLastRaisedEvent: number;

  constructor() {
    this.lastKnownState = 0;
    this.nLastRaisedEvent = 50;
  }

  GetLastKnownState(): number {
    return this.lastKnownState;
  }

  SetLastKnownState(state: number) {
    this.lastKnownState = state;
  }

  GetLastRaisedEvent(): number {
    return this.nLastRaisedEvent;
  }

  SetLastRaisedEvent(nEvent: number) {
    this.nLastRaisedEvent = nEvent;
  }
}
