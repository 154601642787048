import { CHANNEL_TYPES, clickToAct, CONTEXTUAL_OPERATION_TYPE, LOG_LEVEL } from '@amc-technology/davinci-api';
import { LoggerService } from '../logger.service';
import { IDataStore } from './IDataStore';
import { IKeyValuePair } from './IKeyValuePair';
export class ClickToActDataStore implements IDataStore {
  private loggerService: LoggerService;
  private isInitialized = false;

  async retrieveData(key: string): Promise<string> {
    // No need to return data here. Using DPG data store, all stored data already comes in as CAD on the interaction
    if (!this.isInitialized) throw new Error('ClickToActDataStore is not initialized.');

    return Promise.resolve("{}");
  }

  async storeData(key: string, data: any): Promise<void> {
    if (!this.isInitialized) throw new Error('ClickToActDataStore is not initialized.');

    this.updateCAD(key, data);
    return;
  }

  initialize(options: {
    [key: string]: any;
    loggerService: LoggerService;
  }): void {
    this.loggerService = options.loggerService;
    this.isInitialized = true;
    return;
  }

  private async updateCAD(interactionId: string, cadItems: IKeyValuePair): Promise<void> {
    const fname = 'updateCAD';
    try {
      this.loggerService.log(LOG_LEVEL.Debug, fname, `CAD Items: ${JSON.stringify(cadItems)}`);
      clickToAct(
        "",
        undefined,
        CHANNEL_TYPES.Telephony,
        CONTEXTUAL_OPERATION_TYPE.UpdateCAD,
        interactionId,
        cadItems
      );

    } catch (error) {
      alert(JSON.stringify(error));
      this.loggerService.log(LOG_LEVEL.Error, fname, `Failed to send CAD.`, { error: error });
    }
  }

}
