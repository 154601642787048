import { INTERACTION_STATES, LOG_LEVEL } from '@amc-technology/davinci-api';

import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { SCCommandFlag } from './Model/Enums/SCCommandFlag';
import { ScapiService } from './scapi.service';
import { SupportedCommands } from './Model/Enums/Commands';

export interface CommandState {
  command: SupportedCommands;
  status: SCCommandFlag;
}

@Injectable({
  providedIn: 'root'
})
export class SiebelCommandStatusService {

  // siebel-command-status.service.ts: implementation of the CSiebelCommandStatus (SiebelCommandStatus.cpp) class.
  //
  //////////////////////////////////////////////////////////////////////


  //////////////////////////////////////////////////////////////////////
  // Construction/Destruction
  //////////////////////////////////////////////////////////////////////

  constructor(private loggerService: LoggerService) {}

  public AllowSuspendResume( allow: boolean ): CommandState[]
  {
    let commands:  SupportedCommands[];
    let statuses:  SCCommandFlag[];
    let commandStates: CommandState[];

    if( allow )
    {
      commandStates = [
        { command: SupportedCommands.SuspendDeselectedCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
        { command: SupportedCommands.ResumeSelectedCall, status: SCCommandFlag.SC_CF_STRPARAMSOK }
      ];
    }
    else
    {
      commandStates = [
        { command: SupportedCommands.SuspendDeselectedCall, status: SCCommandFlag.SC_CF_DISABLED },
        { command: SupportedCommands.ResumeSelectedCall, status: SCCommandFlag.SC_CF_DISABLED }
      ];
    }

    return commandStates;
  }

  public LoggedIn(): CommandState[]
  {
    const commandStates: CommandState[] = [
      { command: SupportedCommands.LogIn, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.ChangeBusyState, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.ResetState, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentAfterCallWork, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    return commandStates;
  }

  public LoggedOut(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.LogIn, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ChangeBusyState, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ResetState, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentAfterCallWork, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    return commandStates;
  }

  public Disabled(): CommandState[]
  {
    const loggedOutStates = this.LoggedOut();

    const commandStates = [
      ...loggedOutStates,
      { command: SupportedCommands.LogIn, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ResetState, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    return commandStates;
  }

  public ConferenceConsult(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    return commandStates;
  }

  public IsConferenceConsult(): boolean // TODO: Write this funcion in SCAPI service
  {
    // return this.scapi.GetCommandStatus(SupportedCommands.ConferenceComplete) == SCCommandFlag.SC_CF_STRPARAMSOK;
    throw new Error("Not implemented");
  }

  public ConferenceComplete(bUpdateWorkmodeOnActiveCall: boolean, mode: number): CommandState[]
  {
    return this.ConnectedContact(bUpdateWorkmodeOnActiveCall, mode);
  }

  public WarmTransferConsult(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    return commandStates;
  }

  public IsWarmTransferConsult(): boolean // TODO: Write this in the SCAPI service
  {
    // return this.scapi.GetCommandStatus(SupportedCommands.TransferComplete) == SCCommandFlag.SC_CF_STRPARAMSOK;
    throw new Error("Not implemented");
  }

  public WarmTransferComplete(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    return commandStates;
  }

  public NoContacts(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      ...(this.AllowSuspendResume(false))
    ];

    return commandStates;
  }

  public EnableMakeCall(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_STRPARAMSOK }
    ];

    return commandStates;
  }

  public PresenceReady(disposition: boolean = false): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_CHECKED },
      { command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentBusy, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentOtherWork, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetDisposition, status: disposition ? SCCommandFlag.SC_CF_NOPARAMSOK : SCCommandFlag.SC_CF_DISABLED }
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'PresenceReady', 'Setting Presence to Ready', { commandStates, disposition });
    return commandStates;
  }

  public PresenceNotReady(disposition: boolean = false): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetAgentBusy, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentOtherWork, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_CHECKED },
      { command: SupportedCommands.SetDisposition, status: disposition ? SCCommandFlag.SC_CF_NOPARAMSOK : SCCommandFlag.SC_CF_DISABLED }
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'PresenceNotReady', 'Setting Presence to Not Ready', { commandStates, disposition });
    return commandStates;
  }

  public PresenceBusy(disposition: boolean = false): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentBusy, status: SCCommandFlag.SC_CF_CHECKED },
      { command: SupportedCommands.SetAgentOtherWork, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetDisposition, status: disposition ? SCCommandFlag.SC_CF_NOPARAMSOK : SCCommandFlag.SC_CF_DISABLED }
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'PresenceBusy', 'Setting Presence to Busy', { commandStates, disposition });
    return commandStates;
  }

  public PresenceOtherWork(disposition: boolean = false): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentBusy, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentOtherWork, status: SCCommandFlag.SC_CF_CHECKED },
      { command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetDisposition, status: disposition ? SCCommandFlag.SC_CF_NOPARAMSOK : SCCommandFlag.SC_CF_DISABLED }
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'PresenceOtherWork', 'Setting Presence to Other Work', { commandStates, disposition });
    return commandStates;
  }

  public InteractionAlerting(): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_BLINKING | SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    return commandStates;
  }

  public InteractionConnected(dispositionEnabled: boolean = false): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SetDisposition, status: dispositionEnabled ? SCCommandFlag.SC_CF_NOPARAMSOK : SCCommandFlag.SC_CF_DISABLED }
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'InteractionConnected', 'Setting Interaction to Connected', { commandStates, dispositionEnabled });
    return commandStates;
  }

  public InteractionDisconnected(dispositionEnabled: Boolean = false, isReady: boolean = false): CommandState[] {
    const commandStates: CommandState[] = [
      { command: SupportedCommands.LogIn, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.ChangeBusyState, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.ResetState, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentAfterCallWork, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetAgentNotReady, status: isReady ? SCCommandFlag.SC_CF_NOPARAMSOK : SCCommandFlag.SC_CF_DISABLED},
      { command: SupportedCommands.SetAgentReady, status: isReady ? SCCommandFlag.SC_CF_DISABLED : SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SetDisposition, status: dispositionEnabled ? SCCommandFlag.SC_CF_NOPARAMSOK : SCCommandFlag.SC_CF_DISABLED }
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'InteractionDisconnected', 'Setting Interaction to Disconnected', { commandStates, dispositionEnabled, isReady });
    return commandStates;
  }

  public InteractionHeld(): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED }
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'InteractionHeld', 'Setting Interaction to Held', { commandStates });
    return commandStates;
  }

  public InteractionInitiated(): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED }
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'InteractionInitiated', 'Setting Interaction to Initiated', { commandStates });
    return commandStates;
  }

  public InteractionsConsult(isConference: Boolean = false, dispositionEnabled: boolean = false): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: isConference ? SCCommandFlag.SC_CF_DISABLED : SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.TransferComplete, status: isConference ? SCCommandFlag.SC_CF_STRPARAMSOK : SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetDisposition, status: dispositionEnabled ? SCCommandFlag.SC_CF_NOPARAMSOK : SCCommandFlag.SC_CF_DISABLED },

      ...(this.AllowSuspendResume( true ))
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'InteractionsConsult', 'Setting Interaction to Consult', { commandStates, isConference, dispositionEnabled });
    return commandStates;
  }

  public InteractionsUnrelated(dispositionEnabled: boolean = false): CommandState[] {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetDisposition, status: dispositionEnabled ? SCCommandFlag.SC_CF_NOPARAMSOK : SCCommandFlag.SC_CF_DISABLED },
      ...(this.AllowSuspendResume( true ))
    ];
    this.loggerService.log(LOG_LEVEL.Trace, 'InteractionsUnrelated', 'Setting Interaction to Unrelated', { commandStates, dispositionEnabled });
    return commandStates;
  }

  public InitateContact(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      ...(this.AllowSuspendResume( false ))
    ];

    return commandStates;
  }

  public AlertingContact(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_BLINKING | SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    return commandStates;
  }

  public AcceptPreview(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_BLINKING | SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    return commandStates;
  }

  public ConnectedContact(bUpdateWorkmodeOnActiveCall: boolean, mode: number): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED },
    ];

    if (bUpdateWorkmodeOnActiveCall && mode == 1)
    {
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }

    commandStates.push({ command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_DISABLED });

    return commandStates;
  }

  public ConnectedOutboundContact(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_NOPARAMSOK }
    ];

    return commandStates;
  }

  public HeldContact(bUpdateWorkmodeOnActiveCall: boolean, mode: number): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED }
    ];

    if (bUpdateWorkmodeOnActiveCall && mode == 1)
    {
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }

    return commandStates;
  }

  public HeldOutboundContact(): CommandState[]
  {
    const commandStates = [
      { command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_STRPARAMSOK },
      { command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_NOPARAMSOK },
      { command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED },
      { command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_NOPARAMSOK }
    ];

    return commandStates;
  }

  public WorkmodeChanged( mode: number, reason: number ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if( mode == 1 ) // ready
    {
      commandStates.push({ command: SupportedCommands.SetAgentAfterCallWork, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK | SCCommandFlag.SC_CF_CHECKED });
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetDisposition, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED });
    }
    else if( mode == 2 ) // not ready
    {
      commandStates.push({ command: SupportedCommands.SetAgentAfterCallWork, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_NOPARAMSOK | SCCommandFlag.SC_CF_CHECKED });
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK | SCCommandFlag.SC_CF_CHECKED });
      commandStates.push({ command: SupportedCommands.SetDisposition, status: SCCommandFlag.SC_CF_DISABLED });
    }
    else if( mode == 4 ) // ACW
    {
      commandStates.push({ command: SupportedCommands.SetAgentAfterCallWork, status: SCCommandFlag.SC_CF_NOPARAMSOK | SCCommandFlag.SC_CF_CHECKED });
      commandStates.push({ command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK | SCCommandFlag.SC_CF_CHECKED});
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetDisposition, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED });
    }
    else if( mode == 103 ) // Callback
    {
      commandStates.push({ command: SupportedCommands.SetAgentAfterCallWork, status: SCCommandFlag.SC_CF_NOPARAMSOK | SCCommandFlag.SC_CF_CHECKED });
      commandStates.push({ command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK | SCCommandFlag.SC_CF_CHECKED});
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else if( mode == 106 ) // Reserve
    {
      commandStates.push({ command: SupportedCommands.LogIn, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ChangeBusyState, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ResetState, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetAgentAfterCallWork, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED });
    }

    return commandStates;
  }

  public SetReservedChecked( bChecked: boolean ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if ( bChecked )
    {
      commandStates.push({ command: SupportedCommands.LogIn, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ChangeBusyState, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ResetState, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetAgentAfterCallWork, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.AttachData, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ConferenceComplete, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.HoldCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.MakeCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.MergeCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.ReleaseCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.RetrieveCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.TransferComplete, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.TransferInit, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.TransferMute, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.UnHoldCall, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.UpdateCurCallData, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SendDTMFSignal, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetCallback, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.AcceptPreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.RejectPreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SkipPreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SkipClosePreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.RejectClosePreview, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED });

      return commandStates;
    }
  }

  public SetDispositionChecked( bChecked: boolean ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if ( bChecked )
    {
      commandStates.push({ command: SupportedCommands.SetDisposition, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.SetDisposition, status: SCCommandFlag.SC_CF_DISABLED });
    }

    return commandStates;
  }

  public SetPreviewDispositionChecked( bChecked: boolean ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if ( bChecked )
    {
      commandStates.push({ command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.PreviewDisposition, status: SCCommandFlag.SC_CF_DISABLED });
    }

    return commandStates;
  }

  public SetPreviewDirectDispositionChecked( bChecked: boolean ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if ( bChecked )
    {
      commandStates.push({ command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.PreviewDirectDisposition, status: SCCommandFlag.SC_CF_DISABLED });
    }

    return commandStates;
  }

  public SetLogoutEnabled( bEnabled: boolean ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if ( bEnabled )
    {
      commandStates.push({ command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.LogOut, status: SCCommandFlag.SC_CF_DISABLED });
    }

    return commandStates;
  }

  public SetNotReadyEnabled( bEnabled: boolean ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if ( bEnabled )
    {
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK | SCCommandFlag.SC_CF_CHECKED });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK | SCCommandFlag.SC_CF_CHECKED });
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }

    return commandStates;
  }

  public SetWorkmodeEnabled( bEnabled: boolean ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if ( bEnabled )
    {
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetAgentAfterCallWork,  status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_NOPARAMSOK });
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.ChangeNotReadyState, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetAgentAfterCallWork,  status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetAgentNotReady, status: SCCommandFlag.SC_CF_DISABLED });
      commandStates.push({ command: SupportedCommands.SetAgentReady, status: SCCommandFlag.SC_CF_DISABLED });
    }

    return commandStates;
  }

  public SetAnswerEnabled( bEnabled: boolean ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if ( bEnabled )
    {
      commandStates.push({ command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.AnswerCall, status: SCCommandFlag.SC_CF_DISABLED });
    }

    return commandStates;
  }

  public SetConferenceEnabled( bEnabled: boolean ): CommandState[]
  {
    const commandStates: CommandState[] = [];

    if ( bEnabled )
    {
      commandStates.push({ command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_NOPARAMSOK });
    }
    else
    {
      commandStates.push({ command: SupportedCommands.ConferenceInit, status: SCCommandFlag.SC_CF_DISABLED });
    }

    return commandStates;
  }

  public IsReady(): boolean
  {
    // * This logic has been moved to the scapi service
    // return this.GetCommandStatus(SupportedCommands.ChangeNotReadyState) == SCCommandFlag.SC_CF_NOPARAMSOK;
    throw new Error("Not implemented, logic moved to scapi service");
  }
}
