export enum DriverSettingsKey {
  DRIVER_SETTINGS_KEY_MCIS_HOST = 'MCISHost',
  DRIVER_SETTINGS_KEY_MCIS_PORT = 'MCISPort',
  DRIVER_SETTINGS_KEY_EVENT_PORT = 'EventPort',
  DRIVER_SETTINGS_KEY_CTI_CHANNELID = 'CTIChannelID',
  DRIVER_SETTINGS_KEY_TRACE_PATH = 'TracePath',
  DRIVER_SETTINGS_KEY_TRACE_FILENAME = 'TraceFileName',
  DRIVER_SETTINGS_KEY_TRACE_LEVEL = 'TraceLevel',
  DRIVER_SETTINGS_KEY_TRACE_MAXSIZE = 'TraceMaxSize',
  DRIVER_SETTINGS_KEY_CAD_MODULE = 'CallDataModule',
  DRIVER_SETTINGS_KEY_THREAD_POOL_SIZE = 'ThreadPoolSize',
  DRIVER_SETTINGS_KEY_IOPORT_READ_TIMEOUT = 'IoPortReadTimeout',
  DRIVER_SETTINGS_KEY_SVC_CHECK_TIMER = 'ServiceCleanupTimerSeconds',
  DRIVER_SETTINGS_KEY_SVC_EXPIRE_TIMER = 'ServiceExpireTimerSeconds',
}

export type DriverSettings = {
  [key in DriverSettingsKey]: string | number;
};

export function DefaultDriverSettings(): DriverSettings {
  const settings: DriverSettings = {
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_MCIS_HOST]: '',
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_MCIS_PORT]: '',
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_EVENT_PORT]: '',
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_CTI_CHANNELID]: '',
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_TRACE_PATH]: '',
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_TRACE_FILENAME]: '',
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_TRACE_LEVEL]: '',
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_TRACE_MAXSIZE]: '',
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_CAD_MODULE]: '',
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_THREAD_POOL_SIZE]: 0,
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_IOPORT_READ_TIMEOUT]: 3000,
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_SVC_CHECK_TIMER]: 300,
    [DriverSettingsKey.DRIVER_SETTINGS_KEY_SVC_EXPIRE_TIMER]: 900,
  };
  return settings;
}
