export enum EVENT_CODES {
  // IAMCModuleState
	EVENT_MODULESTATECHANGE,

	// IAMCDataStore
	EVENT_CAD_DELETE_PENDING,

	// IAMCEventManager
	EVENT_TESTING_EVENTS,

	// IAMCWorkManager
	EVENT_PENDING_WORK,

	// IAMCContactModule
	EVENT_IMMEDIATE_CHANNEL_ARRIVAL,

	// IAMCAgentManager
	EVENT_WORKMODE_CHANGED,
	EVENT_NEW_WORK,
	EVENT_AGENT_LOGIN,
	EVENT_AGENT_LOGOUT,
	EVENT_LOGON_STATE_CHANGED,

	// IAMCRfcServer
	EVENT_RFC_SHUTDOWN_PENDING,

	// IAMCAgentDashboard
	EVENT_CONTACT_CHANGED,
	EVENT_CONTACT_DROPPED,
	EVENT_REFRESH_WORKTOP,
	EVENT_IS_AGENT_CONTROL_UP,

	// IAMCCTIModule
	EVENT_REQUEST_WORKTOP_LIST,
	EVENT_CALL_ABANDON,

	// IAMCChatManagerModule
	EVENT_WEB_CHAT_DROPCONTACT,

	EVENT_CONTACT_ENQUEUED,
	EVENT_CONTACT_DEQUEUED,
	EVENT_CONTACT_REQUEUED,
	EVENT_CONTACT_ABANDONED,
	EVENT_CONTACT_ANSWERED,
	EVENT_UNSOLICITED_WORKMODE_CHANGED,
	EVENT_UNSOLICITED_LOGIN_STATE_CHANGED,

	EVENT_CHAT_CHANGE_REQUEST,
	EVENT_CHAT_NEW_POSTING_REQUEST,

	EVENT_EXCEPTION_OCCURRED,

	// StatModule for SAP
	EVENT_STAT_IN_QUEUE,
	EVENT_STAT_ALERTING,
	EVENT_STAT_INITIATED,
	EVENT_STAT_DROPPED,		// Including ABANDONED scenarios
	EVENT_STAT_ANSWERED,
	EVENT_STAT_TRANSFERRED,
	EVENT_STAT_CONFERENCED,
	EVENT_STAT_RETRIEVED,
	EVENT_STAT_CONFER_INIT_DROPPED__CONFERENCE,
	EVENT_STAT_CONFER_PARTY_DROPPED__NO_CONFERENCE,
	EVENT_STAT_AGENT_STATE_CHANGED,

	// Events for Cisco OEM
	EVENT_CONNECTOR_DATA_CHANGED,
	EVENT_CONNECTOR_AGENT_STATISTICS,
	EVENT_CONNECTOR_SKILL_GROUP_STATISTICS,
	EVENT_CONNECTOR_SKILL_GROUP_ADD,
	EVENT_CONNECTOR_SKILL_GROUP_REMOVE,

	// Failover (added for Cisco UCCE/UCCX, but could be used by others)
	EVENT_SERVER_STATUS_CHANGED,

	// End - add new codes above this line
	ACE_EVENTCODES_NUM_EVENTS
}
