import { Injectable } from '@angular/core';
import { CallHistory } from './Model/CallHistory';

@Injectable({
  providedIn: 'root'
})
export class CallHistoryManagerService {

  private handleHistories: Map<string, CallHistory>;

  constructor() {
    this.handleHistories = new Map();
  }

  ClearAllCalls() {
    this.handleHistories.clear();
  }

  IsCurrentCall(handle: string): boolean {
    return this.handleHistories.has(handle);
  }

  // IsCurrentInboundCall(handle: string, inbound: boolean): boolean {
  //   throw new Error('Not implemented');
  // }

  AddCurrentCall(handle: string, state: number) {
    const newEntry: CallHistory = new CallHistory();
    newEntry.SetLastKnownState(state);
    this.handleHistories.set(handle, newEntry);
  }

  UpdateCurrentCall(handle: string, state: number) {
    const existingCall = this.handleHistories.get(handle);

    if (existingCall) {
      existingCall.SetLastKnownState(state);
    } else {
      this.AddCurrentCall(handle, state);
    }
  }

  GetLastKnownState(handle: string): number {
    let retVal = 0;

    const existingEntry = this.handleHistories.get(handle);
    if (existingEntry) {
      retVal = existingEntry.GetLastKnownState();
    }

    return retVal;
  }

  RemoveCurrentCall(handle: string) {
    this.handleHistories.delete(handle);
  }

  GetLastRaisedEvent(handle: string): number {
    let retVal = 50;

    let callHistory = this.handleHistories.get(handle);

    if (callHistory) {
      retVal = callHistory.GetLastRaisedEvent();
    }

    return retVal;
  }

  SetLastRaisedEvent(handle: string, nEvent: number): number {
    let retVal = 0;

    let callHistory = this.handleHistories.get(handle);

    if (callHistory) {
      callHistory.SetLastRaisedEvent(nEvent);
    }

    return retVal;
  }
}
