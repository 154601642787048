export enum ServiceSettingsKey {
  SERVICE_SETTINGS_KEY_CALL_DATA_COMMAND_PARAMS = 'CallDataCommandParams',
  SERVICE_SETTINGS_KEY_HAS_AGENTBUSY = 'HasAgentBusy',
  SERVICE_SETTINGS_KEY_HAS_ANSWER = 'HasAnswer',
  SERVICE_SETTINGS_KEY_HAS_DISCONNECT = 'HasDisconnect',
  SERVICE_SETTINGS_KEY_UPDATEWORKONACTIVECALL = 'UpdateWorkModeonActiveCall',
  SERVICE_SETTINGS_KEY_HAS_HOLD = 'HasHold',
  SERVICE_SETTINGS_KEY_HAS_FORWARD = 'HasForward',
  SERVICE_SETTINGS_KEY_STATION = 'Station',
  SERVICE_SETTINGS_KEY_USERNAME = 'Username',
  SERVICE_SETTINGS_KEY_ANSWEREDEVENT = 'ScreenPopOnAnswerClick',
  SERVICE_SETTINGS_KEY_LOGINSTATE = 'AgentDefaultStateOnLogin',
  SERVICE_SETTINGS_KEY_SUPPORTOUTBOUND = 'SupportOutbound',
  SERVICE_SETTINGS_KEY_SUPPORTLOGOUTREASON = 'SupportLogoutReason',
  SERVICE_SETTINGS_KEY_REASONKEY = 'LogoutReasonKey',
  SERVICE_SETTINGS_KEY_PREVIEW = 'PreviewMessage',
  SERVICE_SETTINGS_KEY_PREDICTIVE = 'PredictiveMessage',
  SERVICE_SETTINGS_KEY_REMOVECOMMABEFOREANI = 'RemoveCommaBeforeANI',
  SERVICE_SETTINGS_KEY_DISPAYDNISONALERT = 'DisplayDNISOnAlert',
  SERVICE_SETTINGS_KEY_DEFAULTSIEBELIP = 'DefaultSiebelIP',
  SERVICE_SETTINGS_KEY_REFRESHLARGEHANDLEFIRST = 'RefreshLargeHandleFirst',
  SERVICE_SETTINGS_KEY_GETREFRESHORDERONTWOCALLS = 'GetRefreshOrderOnTwoCalls',
  SERVICE_SETTINGS_KEY_SENDDROPEVENTFIRST = 'SendDropEventFirst',
  SERVICE_SETTINGS_KEY_LETDROPEVENTCLEANWORKITEMS = 'LetDropEventCleanWorkItems',
  SERVICE_SETTINGS_KEY_BLOCKEVENTSDURINGLOGOUT = 'BlockEventsDuringLogout',
  SERVICE_SETTINGS_KEY_AUTOLOGOUT = 'AutoLogout',
  SERVICE_SETTINGS_KEY_USEAUTODISPOSITION = 'UseAutoDisposition',
  SERVICE_SETTINGS_KEY_SHOWALLCALLNOTIFICATION = 'ShowAllCallNotification',
  SERVICE_SETTINGS_KEY_DISABLEWORKMODEDURINGCALLS = 'DisableWorkmodeDuringCalls',
  SERVICE_SETTINGS_KEY_MAKECALL_DATA_PARAMS = 'MakeCallDataParams',
  SERVICE_SETTINGS_KEY_IGNORE_LOGIN = 'IgnoreLogin',
  SERVICE_SETTINGS_KEY_USEWORKMODE_FORACW = 'UseWorkmodeButtonForACW',
  SERVICE_SETTINGS_KEY_SENDCONNECTED_AFTERANSWER = 'SendConnectedAfterAnswer',
  SERVICE_SETTINGS_KEY_KEYNAME_TOTRANSFERCADS = 'KeyNameToTransferCADs',
  SERVICE_SETTINGS_KEY_MAPLOCALIP_TONATIP= 'MapLocalIPtoNATIP',
  SERVICE_SETTINGS_KEY_MULTICCS_SIEBEL_DATA = 'MultiCCSForSiebelData',
  SERVICE_SETTINGS_KEY_TIMED_PREVIEW_KEY_Name = 'PreviewTimeoutKeyName',
  SERVICE_SETTINGS_KEY_SKIP_CALL_DISPOSITION = 'SkipCallDisposition',
  SERVICE_SETTINGS_KEY_SKIP_PREVIEW_DISPOSITION = 'SkipPreviewDisposition',
  SERVICE_SETTINGS_KEY_PREVIEWANI_FROM_CADFIELD = 'PreviewANIFromCADField'
}

export type ServiceSettings = {
  [key in ServiceSettingsKey]: any;
};

export function DefaultServiceSettings(): ServiceSettings {
  const settings: ServiceSettings = {
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_CALL_DATA_COMMAND_PARAMS]: '',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_HAS_AGENTBUSY]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_HAS_ANSWER]: true,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_HAS_DISCONNECT]: true,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_UPDATEWORKONACTIVECALL]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_HAS_HOLD]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_HAS_FORWARD]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_STATION]: '',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_USERNAME]: '',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_ANSWEREDEVENT]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_LOGINSTATE]: 2,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_SUPPORTOUTBOUND]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_SUPPORTLOGOUTREASON]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_REASONKEY]: 'LOGOUTREASON',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_PREVIEW]: 'Preview',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_PREDICTIVE]: 'Predictive',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_REMOVECOMMABEFOREANI]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_DISPAYDNISONALERT]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_DEFAULTSIEBELIP]: '',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_REFRESHLARGEHANDLEFIRST]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_GETREFRESHORDERONTWOCALLS]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_SENDDROPEVENTFIRST]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_LETDROPEVENTCLEANWORKITEMS]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_BLOCKEVENTSDURINGLOGOUT]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_AUTOLOGOUT]: true,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_USEAUTODISPOSITION]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_SHOWALLCALLNOTIFICATION]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_DISABLEWORKMODEDURINGCALLS]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_MAKECALL_DATA_PARAMS]: '',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_IGNORE_LOGIN]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_USEWORKMODE_FORACW]: true,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_SENDCONNECTED_AFTERANSWER]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_KEYNAME_TOTRANSFERCADS]: 'CallVariable1',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_MAPLOCALIP_TONATIP]: '',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_MULTICCS_SIEBEL_DATA]: '',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_TIMED_PREVIEW_KEY_Name]: 'PreviewTimeout',
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_SKIP_CALL_DISPOSITION]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_SKIP_PREVIEW_DISPOSITION]: false,
    [ServiceSettingsKey.SERVICE_SETTINGS_KEY_PREVIEWANI_FROM_CADFIELD]: ''
  };
  return settings;
};
