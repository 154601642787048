export enum Val {
  VAL_WORKTOP = 'Worktop',
  VAL_EXTENSION = 'Extension',
  VAL_HANDLE = 'Handle',
  VAL_METHOD = 'MethodName',
  VAL_SET_CALL_DATA_REQ = 'SET_CALL_DATA_REQ',
  VAL_METHOD_VALUE = 'PreviewCall',
  VAL_ACTION = 'Action',
  VAL_ACTION_VALUE = 'PreviewAction',
  VAL_ACTION_CODE = 'ActionCode',
  VAL_ACTION_CODE_ACCEPT = 'Accept',
  VAL_ACTION_CODE_SKIP = 'Skip',
  VAL_ACTION_CODE_REJECT = 'Reject',
  VAL_ACTION_CODE_SKIPCLOSE = 'SkipClose',
  VAL_ACTION_CODE_REJECTCLOSE = 'RejectClose',
  VAL_CALL_RESULT = 'CallResult',
  VAL_CALL_WRAPUP_DATA = 'CallWrapupData',
  VAL_METHOD_SETDISPOSITION_VALUE = 'SET_CALL_WRAPUP_DATA_REQ',
  VAL_ACTION_CALLBACKDT_CODE = 'CallbackDateTime'
}

export type Vals = {
  [key in Val]: string;
};

export function DefaultVals(): Vals {
  const vals: Vals = {
    [Val.VAL_WORKTOP]: 'Worktop',
    [Val.VAL_EXTENSION]: 'Extension',
    [Val.VAL_HANDLE]: 'Handle',
    [Val.VAL_METHOD]: 'MethodName',
    [Val.VAL_SET_CALL_DATA_REQ]: 'SET_CALL_DATA_REQ',
    [Val.VAL_METHOD_VALUE]: 'PreviewCall',
    [Val.VAL_ACTION]: 'Action',
    [Val.VAL_ACTION_VALUE]: 'PreviewAction',
    [Val.VAL_ACTION_CODE]: 'ActionCode',
    [Val.VAL_ACTION_CODE_ACCEPT]: 'Accept',
    [Val.VAL_ACTION_CODE_SKIP]: 'Skip',
    [Val.VAL_ACTION_CODE_REJECT]: 'Reject',
    [Val.VAL_ACTION_CODE_SKIPCLOSE]: 'SkipClose',
    [Val.VAL_ACTION_CODE_REJECTCLOSE]: 'RejectClose',
    [Val.VAL_CALL_RESULT]: 'CallResult',
    [Val.VAL_CALL_WRAPUP_DATA]: 'CallWrapupData',
    [Val.VAL_METHOD_SETDISPOSITION_VALUE]: 'SET_CALL_WRAPUP_DATA_REQ',
    [Val.VAL_ACTION_CALLBACKDT_CODE]: 'CallbackDateTime'
  };
  return vals;
}
