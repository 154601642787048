import { ClickToActDataStore } from './Model/ClickToActDataStore';
import { Injectable } from '@angular/core';
import { IDataStore } from './Model/IDataStore';
import { BusinessServiceDataStore } from './Model/BusinessServiceDataStore';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService implements IDataStore {
  private dataStore: IDataStore = new ClickToActDataStore();
  private cname = "DataStoreService:";

  constructor(private loggerService: LoggerService) {
  }
  retrieveData(key: string): Promise<string> {
    let fname = `${this.cname}retrieveData(): `;
    try {
      return this.dataStore.retrieveData(key);
    } catch (error) {
      this.loggerService.logger.logError(`${fname} ${JSON.stringify(error)}`);
    }
  }
  initialize(options: { [key: string]: any; loggerService: LoggerService; }): void {
    let fname = `${this.cname}initialize(): `;
    try {
      this.dataStore.initialize(options);
    } catch (error) {
      this.loggerService.logger.logError(`${fname} ${JSON.stringify(error)}`);
    }
  }

  public storeData(key: string, data: any): Promise<void> {
    let fname = `${this.cname}storeData(): `;
    try {
      return this.dataStore.storeData(key, data);
    } catch (error) {
      this.loggerService.logger.logError(`${fname} ${JSON.stringify(error)}`);
    }
  }
}
