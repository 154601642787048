export enum Presence {
  Ready = 'Ready',
  NotReady = 'Not Ready',
  Busy = 'Busy',
  AfterCallWork = 'After Call Work',
  OtherWork = 'Other Work'
}

export enum Reason {
  NoReason = 'No Reason',
  Lunch = 'Lunch',
  Break = 'Break',
  Meeting = 'Meeting'
}
