export enum CadName {
  CAD_NAME_SIEBEL_DATA = 'SIEBELDATA',
  CAD_NAME_CALLBACK_DATA = 'CallbackData',
  CAD_NAME_POSTROUTE_DATA = 'PostRouteData'
}

export type CadNames = {
  [key in CadName]: string;
};

export function DefaultCadNames(): CadNames {
  const names: CadNames = {
    [CadName.CAD_NAME_SIEBEL_DATA]: 'SIEBELDATA',
    [CadName.CAD_NAME_CALLBACK_DATA]: 'CallbackData',
    [CadName.CAD_NAME_POSTROUTE_DATA]: 'PostRouteData'
  };
  return names;
}
