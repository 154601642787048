export enum ClientCommands {
  BeginBatch = 'BeginBatch',
  CacheCommandInformation = 'CacheCommandInformation',
  CleanAllWorkItem = 'CleanAllWorkItem',
  EndBatch = 'EndBatch',
  HandleError = 'HandleError',
  HandleEvent = 'HandleEvent',
  IndicateNewWorkItem = 'IndicateNewWorkItem',
  ShowStatusText = 'ShowStatusText',
  UpdateObjectInformation = 'UpdateObjectInformation',
  WorkItemReleased = 'WorkItemReleased',
  WorkItemResumed = 'WorkItemResumed',
  WorkItemStarted = 'WorkItemStarted',
  WorkItemSuspended = 'WorkItemSuspended'
}
