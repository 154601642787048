  export enum EVENTS_ENUM {
    EVT_MCIS_ALERTING = 0,
    EVT_MCIS_ANSWERED,
    EVT_MCIS_CONNECTED,
    EVT_MCIS_HELD,
    EVT_MCIS_DROPPED,
    EVT_MCIS_RETRIEVED,
    EVT_MCIS_TRANSFERRED,
    EVT_MCIS_CONFERENCED,
    EVT_MCIS_TRANSFER_INITIATED,
    EVT_MCIS_CONFERENCE_INITIATED,
    EVT_MCIS_INITIATED,
    EVT_MCIS_DISPOSITION,
    EVT_MCIS_PREVIEW,
    EVT_MCIS_PREDICTIVE,
    NUM_EVENTS
  };

  export enum SupportedEvents {
    MCISAlerting = "MCISAlerting",
    MCISAnswered = "MCISAnswered",
    MCISConnected = "MCISConnected",
    MCISHeld = "MCISHeld",
    MCISDropped = "MCISDropped",
    MCISRetrieved = "MCISRetrieved",
    MCISTransferred = "MCISTransferred",
    MCISConferenced = "MCISConferenced",
    MCISTransferInitiated = "MCISTransferInitiated",
    MCISConferenceInitiated = "MCISConferenceInitiated",
    MCISInitiated = "MCISInitiated",
    MCISDisposition = "MCISDisposition",
    MCISPreview = "MCISPreview",
    MCISPredictive = "MCISPredictive"
};

export enum SupportedCommands {
  AttachData = "AttachData",
  ChangeBusyState = "ChangeBusyState",
  AnswerCall = "AnswerCall",
  ConferenceComplete = "ConferenceComplete",
  ConferenceInit = "ConferenceInit",
  HoldCall = "HoldCall",
  LogIn = "LogIn",
  LogOut = "LogOut",
  MakeCall = "MakeCall",
  MergeCall = "MergeCall",
  ChangeNotReadyState = "ChangeNotReadyState",
  ReleaseCall = "ReleaseCall",
  ResetState = "ResetState",
  RetrieveCall = "RetrieveCall",
  UpdateCurCallData = "UpdateCurCallData",
  SuspendDeselectedCall = "SuspendDeselectedCall",
  ResumeSelectedCall = "ResumeSelectedCall",
  SendDTMFSignal = "SendDTMFSignal",
  SetAgentAfterCallWork = "SetAgentAfterCallWork",
  SetAgentNotReady = "SetAgentNotReady",
  SetAgentReady = "SetAgentReady",
  TransferComplete = "TransferComplete",
  TransferInit = "TransferInit",
  TransferMute = "TransferMute",
  UnHoldCall = "UnHoldCall",
  UnloadDriver = "UnloadDriver",
  SetDisposition = "SetDisposition",
  SetCallback = "SetCallback",
  AcceptPreview = "AcceptPreview",
  RejectPreview = "RejectPreview",
  SkipPreview = "SkipPreview",
  SkipClosePreview = "SkipClosePreview",
  RejectClosePreview = "RejectClosePreview",
  PreviewDisposition = "PreviewDisposition",
  PreviewDirectDisposition = "PreviewDirectDisposition",
  SetAgentBusy = "SetAgentBusy",
  ToggleForward = "ToggleForward",
  SetAgentOtherWork = "SetAgentOtherWork",
  SendCAD = "SendCAD"
};

export enum CommandDescriptions {
  AttachData = "AttachData",
  ChangeBusyState = "ChangeBusyState",
  AnswerCall = "AnswerCall",
  ConferenceComplete = "ConferenceComplete",
  ConferenceInit = "ConferenceInit",
  HoldCall = "HoldCall",
  LogIn = "LogIn",
  LogOut = "LogOut",
  MakeCall = "MakeCall",
  MergeCall = "MergeCall",
  ChangeNotReadyState = "ChangeNotReadyState",
  ReleaseCall = "ReleaseCall",
  ResetState = "ResetState",
  RetrieveCall = "RetrieveCall",
  UpdateCurCallData = "UpdateCurCallData",
  SuspendDeselectedCall = "SuspendDeselectedCall",
  ResumeSelectedCall = "ResumeSelectedCall",
  SendDTMFSignal = "SendDTMFSignal",
  SetAgentAfterCallWork = "SetAgentAfterCallWork",
  SetAgentNotReady = "SetAgentNotReady",
  SetAgentReady = "SetAgentReady",
  TransferComplete = "TransferComplete",
  TransferInit = "TransferInit",
  TransferMute = "TransferMute",
  UnHoldCall = "UnHoldCall",
  UnloadDriver = "UnloadDriver",
  SetDisposition = "SetDisposition",
  SetCallback = "SetCallback",
  AcceptPreview = "AcceptPreview",
  RejectPreview = "RejectPreview",
  SkipPreview = "SkipPreview",
  SkipClosePreview = "SkipClosePreview",
  RejectClosePreview = "RejectClosePreview",
  PreviewDisposition = "PreviewDisposition",
  PreviewDirectDisposition = "PreviewDirectDisposition",
  SetAgentBusy = "SetAgentBusy",
  ToggleForward = "ToggleForward",
  SetAgentOtherWork = "SetAgentOtherWork"
};
