// These values are taken from the legacy premise Siebel adapter.
// They have no meaning other than to complement the logic that was
// adapted from the ancestor premise project.
//
// Out with the old. In with DaVinci.
//              - Joshua Peck

export enum LEGACY_WORKMODE {
  WM_READY = 1,
  WM_NOT_READY = 2,
  WM_ACW = 4,
}
