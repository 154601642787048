import { Subject } from 'rxjs';
import { CadNames, DefaultCadNames } from './Model/Enums/CadName';
import { CommandParameters, DefaultCommandParameters } from './Model/Enums/CommandParameter';
import { DefaultDriverSettings, DriverSettings } from './Model/Enums/DriverSettingsKey';
import { DefaultServiceSettings, ServiceSettings } from './Model/Enums/ServiceSettingsKey';
import { DefaultVals, Vals } from './Model/Enums/Val';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SiebelConfigService {
  private _driverSettings: DriverSettings;
  private _serviceSettings: ServiceSettings

  public cadName: CadNames;
  public vals: Vals;
  public commandParameters: CommandParameters;
  public onConfigSet = new Subject<void>();

  constructor() {
    this._driverSettings = DefaultDriverSettings();
    this._serviceSettings = DefaultServiceSettings();
    this.cadName = DefaultCadNames();
    this.vals = DefaultVals();
    this.commandParameters = DefaultCommandParameters();
   }

   public set driverSettings(settings: DriverSettings) {
    this.setDriverSettings(settings);
   }

   public setDriverSettings(settings: DriverSettings) {
    for (const key of Object.keys(settings)) {
      this._driverSettings[key] = settings[key];
    }
    this.onConfigSet.next();
   }

   public set serviceSettings(settings: ServiceSettings) {
    this.setServiceSettings(settings);
   }

   public setServiceSettings(settings: ServiceSettings) {
    for (const key of Object.keys(settings)) {
      this._serviceSettings[key] = settings[key];
    }
    this.onConfigSet.next();
   }

   public getDriverSetting(key: string): string | number  {
      return this._driverSettings[key];
   }

   public getServiceSetting(key: string): string | number | boolean {
    return this._serviceSettings[key];
   }
}
